import {
  GET_LOANTYPES
} from '../actions/types';
  
const INITIAL_STATE = {
  loanTypes: []
};
  
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOANTYPES:
      return {
        ...state,
        loanTypes: action.data
      };
    default:
      return state;
  }
};