import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import RateOptimizationDetails from './details';
import RateOptimizationForm from './form';

import { getRateOptimizeListing } from '../../../../actions';

class RateOptimizationTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      details: '',
      dialogContentType: 'details',
    };
    this.onClickOpenDialog = this.onClickOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.onClickViewDetail = this.onClickViewDetail.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
  }

  componentDidMount() {
    this.props.getRateOptimizeListing();
  }

  onClickOpenDialog(dialogContentType) {
    this.setState(prevState => ({
      ...prevState,
      openDialog: true,
      dialogContentType,
    }));
  }
  
  handleCloseDialog() {
    this.setState(prevState => ({
      ...prevState,
      openDialog: false,
    }));
  }

  onClickViewDetail(dialogContentType, id) {
    const { rateOptimize } = this.props;
    const details = rateOptimize.find(x => x._id === id);
    this.setState(prevState => ({
      ...prevState,
      details,
    }), () => this.onClickOpenDialog(dialogContentType));
  }

  onClickEdit(dialogContentType, id) {
    const { rateOptimize } = this.props;
    const details = rateOptimize.find(x => x._id === id);
    this.setState(prevState => ({
      ...prevState,
      details
    }), () => this.onClickOpenDialog(dialogContentType));
  }
  
  renderTable() {
    let result = '';
    const { rateOptimize } = this.props;
    if (rateOptimize && rateOptimize.length) {
      result = (<TableContainer elevation={0}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Lower Limit</TableCell>
              <TableCell align="right">Upper Limit</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rateOptimize.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">{row.lowerLimit}</TableCell>
                <TableCell align="right">{row.upperLimit}</TableCell>
                <TableCell align="center">
                  <IconButton type="button" color="primary" className="btn btn-action" aria-label="View details" onClick={() => this.onClickViewDetail('details', row._id)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton type="button" color="primary" className="btn btn-action" aria-label="Edit" onClick={() => this.onClickEdit('edit', row._id)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>);
    }
    return result;
  }

  render() {
    const { details } = this.state;
    let dialogContent;
    switch (this.state.dialogContentType) {
      case 'edit':
        dialogContent = details ? (
          <RateOptimizationForm details={details} onClose={this.handleCloseDialog} parentProps={this.props} />
        ) : '';
        break;
      default:
        dialogContent = details ? (
          <RateOptimizationDetails details={details} />
        ) : '';
        break;
    }
    return (
      <Fragment>
        {this.renderTable()}
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          fullWidth={true}
          maxWidth='md'
        >
          <DialogContent>{dialogContent}</DialogContent>
        </Dialog>
      </Fragment>
    );
  }

}

const mapStateToProps = state => {
  return {
    rateOptimize: state.setting.rateOptimize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRateOptimizeListing: () => dispatch(getRateOptimizeListing())
  };
};

RateOptimizationTable.propTypes = {
  rateOptimize: PropTypes.array, 
  getRateOptimizeListing: PropTypes.func 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RateOptimizationTable));
