import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

class LTVInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
    this.calculateLTV = this.calculateLTV.bind(this);
  }

  componentDidMount() {
    const { loanAmount, propertyValue } = this.props;
    this.calculateLTV({ loanAmount, propertyValue });
  }

  componentDidUpdate(prevProps) {
    const { loanAmount, propertyValue } = this.props;
    if (loanAmount && propertyValue) { 
      if (prevProps.loanAmount !== loanAmount || prevProps.propertyValue !== propertyValue) {
        this.calculateLTV({ loanAmount, propertyValue });
      }
    }
  }
  
  calculateLTV = ({ loanAmount, propertyValue }) => {
    let roundedLTV = 0;
    if (loanAmount && propertyValue) {
      const ltv = loanAmount * 100 / propertyValue;
      const digit = 10 ** 2;
      roundedLTV = Math.round((ltv + Number.EPSILON) * digit) / digit;
    }
    this.setState({
      value: roundedLTV,
    });
  };

  renderLTVInput() {
    let result;
    result = (
      <Form.Group controlId={`ltv-${Date.now()}`}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <Form.Control type="text" value={this.state.value} disabled={true} />
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderLTVInput();
  }
}

LTVInput.propTypes = {
  label: PropTypes.string,
  loanAmount: PropTypes.number,
  propertyValue: PropTypes.number,
};

export default LTVInput;

