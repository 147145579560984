import { ApiService } from './api.service';
let apiService = new ApiService();

export const QuoteService = {
  getQuote,
  getQuoteOptimize,
};

function getQuote(assumptions) {
  return apiService.post('public/v1/rate', assumptions);
}
function getQuoteOptimize(assumptions) {
  return apiService.post('public/v1/rate/optimize', assumptions);
}