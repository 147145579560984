import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './../Header';
import Spinner from './../../Shared/Spinner';
import './Portal.scss';
class PortalLayout extends Component {

  render() {
    return (
      <Fragment>
        <Header />
        <div className={`content-wrapper${this.props.home ? ' home' : ''}`}>
          {this.props.children}
          {this.props.loading ? <Spinner/> : null}
        </div>
      </Fragment>
    );
  }

}

const mapStateToProps = state => {
  return {
    loading: state.spinner.loading,
  };
};

PortalLayout.propTypes = {
  loading: PropTypes.bool, 
  home: PropTypes.bool, 
  children: PropTypes.any,
};

export default withRouter(connect(mapStateToProps, null)(PortalLayout));