import React, { Component, Fragment } from 'react';
import RealTimeRate from '../../components/RealTimeRate/Home';
import LoanAssistant from '../../components/LoanAssistant/Home';
import './style.scss';

class HomeContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loanAssistant: true,
    };
    this.handleRealTimeRateNextStage = this.handleRealTimeRateNextStage.bind(this);
  }

  handleRealTimeRateNextStage = (nextStage) => {
    this.setState({loanAssistant: !nextStage});
  }

  render() {
    return (
      <Fragment>
        <RealTimeRate nextStage={this.handleRealTimeRateNextStage}/>
        {this.state.loanAssistant ? <LoanAssistant /> : null}
      </Fragment>
    );
  }
}

export default HomeContainer;
