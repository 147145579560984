import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Form, Row, Col, Button } from 'react-bootstrap';

import { updateRateOptimize } from '../../../../actions';

class RateOptimizationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataForm: {},
    };
    this.onCancel = this.onCancel.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeRange = this.onChangeRange.bind(this);
    this.onChangeRangeValues = this.onChangeRangeValues.bind(this);
  }

  componentDidMount() {
    const { details } = this.props;
    this.setState(prevState => ({
      ...prevState,
      dataForm: details,
    }));
  }

  onChangeValue(event) {
    const { value, name } = event.target;
    this.setState(prevState => ({
      ...prevState,
      dataForm: {
        ...prevState.dataForm,
        [name]: value,
      },
    }));
  }

  onChangeRange(event, key, index) {
    const { value } = event.target;
    const { range } = this.state.dataForm;
    range[index][key] = +value;
    this.setState(prevState => ({
      ...prevState,
      dataForm: {
        ...prevState.dataForm,
        range,
      },
    }));
  }

  onChangeRangeValues(event, rangIndex, valueIndex) {
    const { value } = event.target;
    const { range } = this.state.dataForm;
    const newValue = value.split(',').reduce((arrayValue, currentValue) => {
      return [...arrayValue, +currentValue];
    },[]);
    range[rangIndex].values[valueIndex] = newValue;
    this.setState(prevState => ({
      ...prevState,
      dataForm: {
        ...prevState.dataForm,
        range,
      },
    }));
  }

  onCancel() {
    this.props.onClose();
  }

  handleSubmitForm() {
    const { dataForm } = this.state;
    const { _id: id } = dataForm;
    this.props.updateRateOptimize(id, dataForm, this.props);
    this.props.onClose();
  }

  renderdataForm() {
    let result;
    const { dataForm } = this.state;
    if (dataForm && Object.keys(dataForm).length) {
      const { name, lowerLimit, upperLimit, direction, range } = dataForm;
      result = (
        <Card elevation={0}>
          <CardHeader
            title={
              <h5 className="title">Edit</h5>
            }
          />
          <CardContent>
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control size="sm" type="text" name="name" defaultValue={name} onChange={this.onChangeValue}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formLowerLimit">
                    <Form.Label>Lower limit</Form.Label>
                    <Form.Control size="sm" type="number" name="lowerLimit" defaultValue={lowerLimit} onChange={this.onChangeValue}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formUpperLimit">
                    <Form.Label>Upper limit</Form.Label>
                    <Form.Control size="sm" type="number" name="upperLimit" defaultValue={upperLimit} onChange={this.onChangeValue}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formDirection">
                    <Form.Label>Direction</Form.Label>
                    <Form.Control as="select" name="direction" defaultValue={direction} onChange={this.onChangeValue}>
                      <option value="left">Left</option>
                      <option value="right">Right</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TableContainer component={Paper} square={true} elevation={0}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>From</TableCell>
                          <TableCell>To</TableCell>
                          <TableCell>Values</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {range.map((x, idx) => {
                          const { from, to, values } = x;
                          return (
                            <TableRow key={idx}>
                              <TableCell>
                                <Form.Control size="sm" type="number" name={`from-${idx}`} defaultValue={from} onChange={(e) => this.onChangeRange(e, 'from', idx)} />
                              </TableCell>
                              <TableCell>
                                <Form.Control size="sm" type="number" name={`to-${idx}`} defaultValue={to} onChange={(e) => this.onChangeRange(e, 'to', idx)} />
                              </TableCell>
                              <TableCell>
                                {values.map((value, i) => (
                                  <Form.Control key={i} size="sm" type="text" name={`value-${i}`} className="mb-1" defaultValue={value} onChange={(e) => this.onChangeRangeValues(e, idx, i)} />
                                ))}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr></hr>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    size="sm" 
                    className="mr-2"
                    onClick={this.onCancel}
                  >
                  Cancel
                  </Button>
                  <Button 
                    color="primary" 
                    size="sm"
                    type="button"
                    onClick={this.handleSubmitForm}>Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardContent>
        </Card>
      );
    }
    return result;
  }

  render() {
    return (
      <Fragment>
        {this.renderdataForm()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRateOptimize: (id, rateOptimize, ownProps) => dispatch(updateRateOptimize(id, rateOptimize, ownProps))
  };
};

RateOptimizationForm.propTypes = {
  details: PropTypes.object,
  parentProps: PropTypes.object,
  updateRateOptimize: PropTypes.func,
  onClose: PropTypes.func,
};

export default withRouter(connect(null, mapDispatchToProps)(RateOptimizationForm));  