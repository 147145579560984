import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class RateOptimizationDetails extends Component {
    
  renderDetails() {
    let result;
    const { details } = this.props;
    if (details && Object.keys(details).length) {
      const { name, lowerLimit, upperLimit, direction, range } = details;
      result = (
        <Fragment>
          <h5>{name}</h5>
          <span>[{lowerLimit} - {upperLimit}]</span>
          <TableContainer component={Paper} square={true} elevation={0}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Values (Direction: {direction})</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {range.map((x, idx) => {
                  const { from, to, values } = x;
                  return (
                    <TableRow key={idx}>
                      <TableCell>{from}</TableCell>
                      <TableCell>{to}</TableCell>
                      <TableCell>{
                        values.map((value, index) => {
                          return `${value.join(' - ')}${(index + 1) !== values.length ? ', ' : '' }`;
                        })
                      }</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      );
    }
    return result;
  }

  render() {
    return this.renderDetails();
  }
}

RateOptimizationDetails.propTypes = {
  details: PropTypes.object,
};

export default RateOptimizationDetails;