// alert
export const SHOW_ALERT = 'SHOW_ALERT';

// spinner
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

// quote
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS';
export const GET_QUOTE_OPTIMIZE_SUCCESS = 'GET_QUOTE_OPTIMIZE_SUCCESS';

// loan type
export const GET_LOANTYPES = 'GET_LOANTYPES';

// calculator
export const GET_MORTGAGE_CALCULATE = 'GET_MORTGAGE_CALCULATE';
export const COMPARISON_RATE = 'COMPARISON_RATE';
export const REMAINING_MONTHS = 'REMAINING_MONTHS';
export const PURCHASE_POWER = 'PURCHASE_POWER';

// authentication
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

// optimize rate
export const GET_OPTIMIZE_RATES = 'GET_OPTIMIZE_RATES';

// lowest rate lenders
export const GET_LENDER_LOWEST_RATE = 'GET_LENDER_LOWEST_RATE';
export const UPDATE_LENDER_LOWEST_RATE_SUCCESS = 'UPDATE_LENDER_LOWEST_RATE_SUCCESS';
export const UPDATE_LENDER_LOWEST_RATE_FAILURE = 'UPDATE_LENDER_LOWEST_RATE_FAILURE';