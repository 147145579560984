import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PropertyUsageSelectBox from '../Controls/SelectBoxPropertyUsage';
import PropertyTypeSelectBox from '../Controls/SelectBoxPropertyType';
import CreditScoreSelectBox from '../Controls/SelectBoxCreditScore';
// import LoanTypeSelectBox from '../Controls/SelectBoxLoanType';

import './style.scss';
class AdvancedAssumptions extends Component {

  constructor(props) {
    super(props);
    const advancedAssumptions = this.props.advancedAssumptions;
    this.state = {
      openAdvancedOptions: false,
      assumptions: {
        loanType: advancedAssumptions.loanType,
        creditScore: advancedAssumptions.creditScore,
        propertyType: advancedAssumptions.propertyType,
        propertyUsage: advancedAssumptions.propertyUsage,
        lockDays: advancedAssumptions.lockDays,
      }
    };
    this.handleAssumptionsChange = this.handleAssumptionsChange.bind(this);
    this.getDefaultLoanType = this.getDefaultLoanType.bind(this);
    this.handleOpenAdvancedOptions = this.handleOpenAdvancedOptions.bind(this);
  }

  handleAssumptionsChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      assumptions: {
        ...prevState.assumptions,
        [fieldName]: value,
      }
    }), () => this.props.onAdvancedAssumptionsFetched(this.state.assumptions));
  }

  handleOpenAdvancedOptions() {
    this.setState(prevState => ({
      ...prevState,
      openAdvancedOptions: !prevState.openAdvancedOptions
    }));
  }

  getDefaultLoanType(loanType) {
    this.setState(prevState => ({
      ...prevState,
      assumptions: {
        ...prevState.assumptions,
        loanType
      }
    }));
  }

  render() {
    return (
      <Fragment>
        <p className="addvanced-optiions-text" onClick={this.handleOpenAdvancedOptions}>Advanced options {!this.state.openAdvancedOptions ? <ExpandMoreIcon /> : <ExpandLessIcon />}</p>
        <Container fluid className={`advanced-assumptions ${!this.state.openAdvancedOptions ? 'hide' : null}`}>
          <Row className="justify-content-md-center">
            <Col xs={12} md={4} lg={3}>
              <CreditScoreSelectBox label="Credit Score" value={this.state.assumptions.creditScore} onChange={this.handleAssumptionsChange} />
            </Col>
            <Col xs={12} md={4} lg={3} className="property-type">
              <PropertyTypeSelectBox label="Property Type" value={this.state.assumptions.propertyType} onChange={this.handleAssumptionsChange} />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <PropertyUsageSelectBox label="Property Usage" value={this.state.assumptions.propertyUsage} onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          {/* <Row className="justify-content-md-center">
            <Col xs={12} md={4} lg={3}>
              <LoanTypeSelectBox label="Loan Type" value={this.state.assumptions.loanType} onChange={this.handleAssumptionsChange} />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <LoanTermSelectBox label="Loan Term" value={this.state.assumptions.loanTerm} onChange={this.handleAssumptionsChange} />
            </Col>
          </Row> */}
        </Container>
      </Fragment>
    );
  }
}

AdvancedAssumptions.propTypes = {
  advancedAssumptions: PropTypes.object,
  onAdvancedAssumptionsFetched: PropTypes.func,
};

export default AdvancedAssumptions;