import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Row, Col, Button } from 'react-bootstrap';
import PageTitle from '../../../components/PageTitle';
import LenderLowestRateAutocomplete from '../../../components/Admin/Controls/AutocompleteLenderLowestRate';
import RateOptimizationTable from '../../../components/Admin/Tables/RateOptimization';

import { updateLowestRateLenders } from '../../../actions';

class SettingsContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lowsetRateLenders: [],
      isReadySave: false,
    };
    this.handleLenderLowestRateChange = this.handleLenderLowestRateChange.bind(this);
    this.onClickSaveLowestRate = this.onClickSaveLowestRate.bind(this);
  }

  handleLenderLowestRateChange(lowsetRateLenders) {
    this.setState(prevState => ({
      ...prevState,
      lowsetRateLenders,
      isReadySave: true,
    }));
  }

  onClickSaveLowestRate() {
    const { lowsetRateLenders } = this.state;
    const lowsetRateLenderIds = lowsetRateLenders.map(lender => lender._id);
    this.props.updateLowestRateLenders(lowsetRateLenderIds, this.props);
  }

  render() {
    const { isReadySave } = this.state;
    return (
      <Fragment>
        <PageTitle title='Settings' />
        <Tabs defaultActiveKey="lowest">
          <Tab eventKey="lowest" title="Lowest Rate">
            <Row>
              <Col>
                <LenderLowestRateAutocomplete onChange={this.handleLenderLowestRateChange}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="primary" disabled={!isReadySave} onClick={this.onClickSaveLowestRate}>Save</Button>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="optimization" title="Rate Optimization">
            <RateOptimizationTable />
          </Tab>
        </Tabs>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateLowestRateLenders: (lenderIds, ownProps) => dispatch(updateLowestRateLenders(lenderIds, ownProps))
  };
};

SettingsContainer.propTypes = {
  updateLowestRateLenders: PropTypes.func,
};

export default withRouter(connect(null, mapDispatchToProps)(SettingsContainer));
