import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import MortgageCalculatorTable from './MortgageCalculatorTable';
import MortgageCalculatorChart from './MortgageCalculatorChart';
import './style.scss';

import { currencyFormat } from '../../../utils';

class MortgageCalculatorResult extends Component {
  constructor(props){
    super(props);
    this.state = {
      paymentChart: true,
      paymentTable: true,
    };
    this.handleClickPaymentChart = this.handleClickPaymentChart.bind(this);
    this.handleClickPaymentTable = this.handleClickPaymentTable.bind(this);
  }

  handleClickPaymentChart() {
    this.setState(prevState => ({
      ...prevState,
      paymentChart: !prevState.paymentChart,
    }));
  }
  
  handleClickPaymentTable() {
    this.setState(prevState => ({
      ...prevState,
      paymentTable: !prevState.paymentTable,
    }));
  }

  render() {
    let mortgageResult;
    const colWidthChart = (this.state.paymentChart && this.state.paymentTable) ? 7 : 8;
    const colWidthTable = (this.state.paymentChart && this.state.paymentTable) ? 5 : 8;
    if(this.props.mortgageResult && Object.keys(this.props.mortgageResult).length > 0) {
      const monthlyPayment = this.props.mortgageResult.monthlyPayment;
      const payments = this.props.mortgageResult.payments;
      const mortgageTable = payments
        .filter((year, i) => i > 0 && (year.balance > 0 || year.interestYearly > 0))
        .reduce(
          (acc, year, index) => ({
            interestTotal: acc.interestTotal + year.interestYearly,
            overpaymentTotal: acc.overpaymentTotal + year.overpayment,
            rows: [
              ...acc.rows,
              [
                year.partial ? year.partial + 'm' : index + 1,
                year.interestYearly || 0,
                year.overpayment || 0,
                year.balance || 0,
              ]
            ]
          }),
          { interestTotal: 0, overpaymentTotal: 0, rows: [] }
        );
      mortgageResult = (
        <Container className="mortgage-calculate-result">
          <Row>
            <Col xs={12} md={7} lg={5}>
              <div className="indent-box result">
                <ul className="results">
                  <li>Monthly Payment: <span className="badge badge-green">{currencyFormat(monthlyPayment || 0)}</span></li>
                  <li>Total Interest Paid: <span className="badge badge-green">{currencyFormat(mortgageTable.interestTotal || 0)}</span></li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <label className="custom-checkbox inline" id={`payment-chart-${Date.now()}`}>Payment Chart
                <input type="checkbox" checked={this.state.paymentChart} onChange={this.handleClickPaymentChart}/>
                <span className="checkmark"></span>
              </label>
              <label className="custom-checkbox inline" id={`payment-table-${Date.now()}`}>Payment Table
                <input type="checkbox" checked={this.state.paymentTable} onChange={this.handleClickPaymentTable}/>
                <span className="checkmark"></span>
              </label>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={colWidthChart}>
              {this.state.paymentChart ? <MortgageCalculatorChart mortgageChart={payments} /> : null}
            </Col>
            <Col xs={12} md={12} lg={colWidthTable}>
              {this.state.paymentTable ? <MortgageCalculatorTable mortgageTable={mortgageTable} /> : null}
            </Col>
          </Row>
        </Container>
      );
    }
    return mortgageResult;
  }
}

MortgageCalculatorResult.propTypes = {
  mortgageResult: PropTypes.object,
};

export default MortgageCalculatorResult;