import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { percentFormat, currencyFormat } from '../../utils';

class SpanValue extends Component {

  renderSpan() {
    let result;
    const value = this.props.value;
    let valueFormatted = '';
    const format = this.props.format;

    if(typeof value === 'undefined') {
      result = (<span>NULL</span>);
    } else {
      const valueParseInt = (value >=0 ) ? value : Math.abs(value);
      switch (format) {
        case 'percent':
          valueFormatted = percentFormat(valueParseInt);
          break;
        case 'currency':
          valueFormatted =  currencyFormat(valueParseInt);
          break;
        default:
          valueFormatted = currencyFormat(valueParseInt);
          break;
      }
      if (value > 0) {
        result = (<span className="cost pay-price">{valueFormatted}</span>);
      } else if (value === 0) {
        result = (<span>{valueFormatted}</span>);
      } else {
        result = (<span className="cost get-price">{valueFormatted}</span>);
      }
    }
    return result;
  }

  render() {
    return this.renderSpan();
  }
}

SpanValue.propTypes = {
  value: PropTypes.number,
  format: PropTypes.string,
};

export default SpanValue;