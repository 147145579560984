import React, { Component, Fragment } from 'react';
import SiteBreadcrumb from '../../components/Breadcrumb';
import SmartInterestReduction from '../../components/LoanAssistant/SmartInterestReduction/SmartInterestReduction';

class SmartInterestReductionContainer extends Component {

  render() {
    const crumbs = [
      { path: '/', name: 'Home'},
      { path: '/', name: 'Smart Interest Reduction'},
    ];
    return (
      <Fragment>
        <SiteBreadcrumb crumbs={crumbs}/>
        <SmartInterestReduction />
      </Fragment>
    );
  }
}

export default SmartInterestReductionContainer;
