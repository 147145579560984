import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CurrencyInput from '../../Controls/InputCurrency';
import RateInput from '../../Controls/InputRate';
import NumberInput from '../../Controls/InputNumber';
import ZipcodeInput from '../../Controls/InputZipcode';
import { currencyFormat } from '../../../utils';
import { calculatePurchasePower } from '../../../actions';
import './style.scss';

class PurchasePower extends Component {
  constructor(props) {
    super(props);
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const mode = params.get('mode');

    this.state = {
      ownPrimaryResidence: true,
      rentPrimaryResidence: 0,
      estimatedRentalIncome: 0,
      monthlyPropertyExpenses: 0,
      propertyExpenses: {
        mortgage: 0,
        tax: 0,
        insurance: 0,
        hoa: 0,
      },
      propertyInfo: {
        bed: null,
        bath: null,
        livingSquareFeet: null,
        zipCode: null,
      },
      propertyUseItAs: 'primary',
      monthlyIncome: 10900,
      monthlyDebt: 0,
      debts: {
        studentLoans: 0,
        carLoans: 0,
        creditCardDue: 0,
        otherDebts: 0,
      },
      rate: 3,
      downPayment: 200000,
      otherProperties: [
        { 
          mortgage: 0,
          tax: 0,
          insurance: 0,
          hoa: 0,
          bed: null,
          bath: null,
          livingSquareFeet: null,
          zipCode: null,
        }
      ],
      existingProperties: [
        { 
          rent: 0,
          mortgage: 0,
          tax: 0,
          insurance: 0,
          hoa: 0,
          filedOnTax: 0,
        }
      ],
      result: {
        netRentalIncomeExistingProperties: 0,
        personalDebt: 0,
        propertyDebt: 0,
        otherPropertyDebt: 0,
        totalDebt: 0,
        personalIcome: 0,
        propertyIcome: 0,
        totalIncome: 0,
        newRentalIncome: 0,
      },
      expandCollapse: {
        personalDebt: false,
        propertyExpenses: false,
        otherProperty: false,
        existingProperty: false,
        propertyFeatures: false,
      },
      mode
    };
    this.handleAssumptionsChange = this.handleAssumptionsChange.bind(this);
    this.handleDebtChange = this.handleDebtChange.bind(this);
    this.updateDebtValue = this.updateDebtValue.bind(this);
    this.handleClickUseItAs = this.handleClickUseItAs.bind(this);
    this.handleClickOwnPrimaryResidence = this.handleClickOwnPrimaryResidence.bind(this);
    this.handlePropertyExpensesChange = this.handlePropertyExpensesChange.bind(this);
    this.handlePropertyInfoChange = this.handlePropertyInfoChange.bind(this);
    this.updatePropertyExpensesValue = this.updatePropertyExpensesValue.bind(this);
    this.handleClickExpandCollapse = this.handleClickExpandCollapse.bind(this);
    this.handleAddOtherProperty = this.handleAddOtherProperty.bind(this);
    this.handleDeleteOtherProperty = this.handleDeleteOtherProperty.bind(this);
    this.handleOtherPropertyChange = this.handleOtherPropertyChange.bind(this);
    this.handleAddExistingProperty = this.handleAddExistingProperty.bind(this);
    this.handleDeleteExistingProperty = this.handleDeleteExistingProperty.bind(this);
    this.handleExistingPropertyChange = this.handleExistingPropertyChange.bind(this);
    this.saveResult = this.saveResult.bind(this);
  }

  componentDidMount() {
    this.mortgageCalculator();
  }

  handleAssumptionsChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }), () => this.mortgageCalculator());
  }

  handleAddOtherProperty() {
    this.setState(prevState => ({
      ...prevState,
      otherProperties: [
        ...prevState.otherProperties,
        { 
          mortgage: 0,
          tax: 0,
          insurance: 0,
          hoa: 0,
          bed: null,
          bath: null,
          livingSquareFeet: null,
          zipCode: null,
        },
      ]
    }));
  }

  handleDeleteOtherProperty(index) {
    const otherProperties = [...this.state.otherProperties];
    if(index >= 0) {
      otherProperties.splice(index, 1);
      this.setState(prevState => ({
        ...prevState,
        otherProperties
      }), () => this.mortgageCalculator());
    }
  }

  handleAddExistingProperty() {
    this.setState(prevState => ({
      ...prevState,
      existingProperties: [
        ...prevState.existingProperties,
        { 
          rent: 0,
          mortgage: 0,
          tax: 0,
          insurance: 0,
          hoa: 0,
          filedOnTax: 0,
        },
      ]
    }));
  }

  handleDeleteExistingProperty(index) {
    const existingProperties = [...this.state.existingProperties];
    if(index >= 0) {
      existingProperties.splice(index, 1);
      this.setState(prevState => ({
        ...prevState,
        existingProperties
      }), () => this.mortgageCalculator());
    }
  }

  handleOtherPropertyChange(value, fieldName, index) {
    const { otherProperties } = this.state;
    if(index >= 0) {
      otherProperties[index] = { ...otherProperties[index], [fieldName]: value };
    }
    this.setState(prevState => ({
      ...prevState,
      otherProperties
    }), () => this.mortgageCalculator());
  }

  handleExistingPropertyChange(value, fieldName, index) {
    const { existingProperties } = this.state;
    if(index >= 0) {
      existingProperties[index] = { ...existingProperties[index], [fieldName]: value };
    }
    this.setState(prevState => ({
      ...prevState,
      existingProperties
    }), () => this.mortgageCalculator());
  }

  handleDebtChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      debts: {
        ...prevState.debts,
        [fieldName]: value,
      },
    }), () => this.updateDebtValue());
  }

  updateDebtValue() {
    const { debts } = this.state;
    let newDebt = 0;
    for (const key in debts) {
      newDebt += +debts[key] || 0;
    }
    this.setState(prevState => ({
      ...prevState,
      monthlyDebt: newDebt,
    }), () => this.mortgageCalculator());
  }

  handlePropertyExpensesChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      propertyExpenses: {
        ...prevState.propertyExpenses,
        [fieldName]: value,
      },
    }), () => this.updatePropertyExpensesValue());
  }

  handlePropertyInfoChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      propertyInfo: {
        ...prevState.propertyInfo,
        [fieldName]: +value,
      },
    }), () => this.mortgageCalculator());
  }

  updatePropertyExpensesValue() {
    const { propertyExpenses } = this.state;
    let newPropertyExpenses = 0;
    for (const key in propertyExpenses) {
      newPropertyExpenses += +propertyExpenses[key] || 0;
    }
    this.setState(prevState => ({
      ...prevState,
      monthlyPropertyExpenses: newPropertyExpenses,
    }), () => this.mortgageCalculator());
  }

  handleClickUseItAs(value) {
    this.setState(prevState => ({
      ...prevState,
      propertyUseItAs: value
    }), () => this.mortgageCalculator());
  }

  handleClickOwnPrimaryResidence() {
    this.setState(prevState => ({
      ...prevState,
      ownPrimaryResidence: !prevState.ownPrimaryResidence
    }), () => this.mortgageCalculator());
  }

  handleClickExpandCollapse(name) {
    this.setState(prevState => ({
      ...prevState,
      expandCollapse: {
        ...prevState.expandCollapse,
        [name]: !prevState.expandCollapse[name],
      }
    }));
  }

  saveResult(result) {
    this.setState(prevState => ({
      ...prevState,
      result
    }));
  }

  mortgageCalculator() {
    const { 
      ownPrimaryResidence,
      rentPrimaryResidence,
      propertyUseItAs,
      monthlyPropertyExpenses,
      estimatedRentalIncome,
      propertyInfo,
      monthlyIncome,
      monthlyDebt,
      downPayment,
      rate,
      otherProperties,
      existingProperties,
    } = this.state;

    const { bed } = propertyInfo;

    let personalDebt = monthlyDebt;
    let propertyDebt = 0;
    let totalDebt = personalDebt + propertyDebt;

    let personalIcome = monthlyIncome;
    let propertyIcome = 0;
    let totalIncome = personalIcome + propertyIcome;

    let homeValue = 0;
    let rentalValue = 0;
    let netIncome = 0;
    let newRentalIncome = 0;

    if(ownPrimaryResidence) {
      switch (bed) {
        case 4:
          homeValue = 4000;
          break;
        case 3:
          homeValue = 3200;
          break;
        case 2:
          homeValue = 2800;
          break;
        case 1:
          homeValue = 2500;
          break;
        default:
          homeValue = 0;
          break;
      }
    }

    if (ownPrimaryResidence && propertyUseItAs === 'primary') {
      rentalValue = homeValue * 0.75;
      netIncome = rentalValue - monthlyPropertyExpenses;
    }
    if (ownPrimaryResidence && propertyUseItAs === 'second') {
      propertyDebt += monthlyPropertyExpenses;
    }
    if (ownPrimaryResidence && propertyUseItAs === 'rental') {
      propertyDebt += monthlyPropertyExpenses;
      newRentalIncome = estimatedRentalIncome * 0.75;
    }

    if (!ownPrimaryResidence && propertyUseItAs === 'second') {
      propertyDebt += rentPrimaryResidence;
    }

    if (!ownPrimaryResidence && propertyUseItAs === 'rental') {
      propertyDebt += rentPrimaryResidence;
      newRentalIncome = estimatedRentalIncome * 0.75;
    }

    if (netIncome < 0) {
      propertyDebt += Math.abs(netIncome);
    } else {
      propertyIcome += netIncome;
    }

    const otherPropertyDebt = otherProperties.reduce((total, currentValue) => {
      const { mortgage, tax, insurance, hoa } = currentValue;
      return total += mortgage + tax + insurance + hoa;
    }, 0);

    const netRentalIncomeExistingProperties = existingProperties.reduce((totalNetRental, currentValue) => {
      const { rent, mortgage, tax, insurance, hoa, filedOnTax } = currentValue;
      const expenses = mortgage + tax + insurance + hoa + filedOnTax;
      const netRental = rent - expenses;
      totalNetRental += netRental;
      return totalNetRental;
    }, 0);

    if (netRentalIncomeExistingProperties < 0) {
      propertyDebt += Math.abs(netRentalIncomeExistingProperties);
    } else {
      propertyIcome += netRentalIncomeExistingProperties;
    }

    totalDebt = personalDebt + propertyDebt + otherPropertyDebt;
    totalIncome = personalIcome + propertyIcome;

    // console.log('--------------------------------');
    // console.log('== Total net rental existing properties:', netRentalIncomeExistingProperties);
    // console.log('== Personal Debt:', personalDebt);
    // console.log('== Property Debt:', propertyDebt);
    // console.log('== Other Property Debt', otherPropertyDebt);
    // console.log('==== Total Debt:', totalDebt);
    // console.log('== Personal Income:', personalIcome);
    // console.log('== Property Income:', propertyIcome);
    // console.log('==== Total Income:', totalIncome);
    // console.log('== New Rental Income:', newRentalIncome);
    
    // Test = (Monthy payment	+ Tax or Insurance + Total Debt) / Total Income = 0.5
    // Test = ((Monthy payment	+ Tax or Insurance) - New Icome + Total Debt) / Total Income = 0.5
    const result = {
      netRentalIncomeExistingProperties,
      personalDebt,
      propertyDebt,
      otherPropertyDebt,
      totalDebt,
      personalIcome,
      propertyIcome,
      totalIncome,
      newRentalIncome,
    };
    this.saveResult(result);

    this.props.calculatePurchasePower(totalIncome, totalDebt, downPayment, rate, newRentalIncome);
  }

  render() {
    const {
      ownPrimaryResidence,
      rentPrimaryResidence,
      propertyUseItAs,
      monthlyPropertyExpenses,
      estimatedRentalIncome,
      propertyExpenses,
      propertyInfo,
      monthlyIncome,
      monthlyDebt,
      debts,
      downPayment,
      rate,
      otherProperties,
      existingProperties,
      expandCollapse,
      result,
      mode
    } = this.state;
    const {
      studentLoans,
      carLoans,
      creditCardDue,
      otherDebts
    } = debts;
    const {
      mortgage,
      tax,
      insurance,
      hoa,
    } = propertyExpenses;
    const {
      bed,
      bath,
      livingSquareFeet,
      zipCode,
    } = propertyInfo;
    const {
      personalDebt: expandPersonalDebt,
      propertyExpenses: expandPropertyExpenses,
      otherProperty: expandOtherProperty,
      existingProperty: expandExistingProperty,
      propertyFeatures: expandPropertyFeatures
    } = expandCollapse;

    const {
      netRentalIncomeExistingProperties,
      personalDebt,
      propertyDebt,
      otherPropertyDebt,
      totalDebt,
      personalIcome,
      propertyIcome,
      totalIncome,
      newRentalIncome,
    } = result;
    
    return (
      <section className="purchase-power-wrapper">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h5 className="page-title">Purchase Power</h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={12} lg={12}>
              <label>Do you own your current primary residence (your current residence address)?</label>
              <div className="custom-control custom-radio inline ml-3">
                <input 
                  type="radio" 
                  className="custom-control-input" 
                  id="ownPrimaryResidenceYes" 
                  name="ownPrimaryResidence" 
                  checked={ownPrimaryResidence} 
                  onChange={this.handleClickOwnPrimaryResidence} />
                <label className="custom-control-label" htmlFor="ownPrimaryResidenceYes">Yes</label>
              </div>
              <div className="custom-control custom-radio inline">
                <input 
                  type="radio" 
                  className="custom-control-input" 
                  id="ownPrimaryResidenceNo" 
                  name="ownPrimaryResidence" 
                  checked={!ownPrimaryResidence}
                  onChange={this.handleClickOwnPrimaryResidence} />
                <label className="custom-control-label" htmlFor="ownPrimaryResidenceNo">No</label>
              </div>
            </Col>
          </Row>
          { ownPrimaryResidence ? 
            <Fragment>
              <Row className="align-items-center">
                <Col xs={12} md={9} lg={6}>
                  <label className="monthly-property-expenses" onClick={()=> this.handleClickExpandCollapse('propertyExpenses')}>
                    Monthly Property Expenses
                    { expandPropertyExpenses ? <RemoveIcon className="collapse-icon"/> : <AddIcon className="expand-icon"/> }
                  </label>
                  { !expandPropertyExpenses ? <span className="badge badge-green total">{currencyFormat(monthlyPropertyExpenses)}</span> : null }
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={9} lg={6}>
                  { expandPropertyExpenses ? <div className="indent-box">
                    <Row className="align-items-center">
                      <Col xs={12} md={3} lg={4}>
                        <label>Monthly Mortgage</label>
                      </Col>
                      <Col xs={12} md={5} lg={8}>
                        <CurrencyInput name="mortgage" value={mortgage} id="mortgage" onChange={this.handlePropertyExpensesChange} />
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col xs={12} md={3} lg={4}>
                        <label>Monthly Tax</label>
                      </Col>
                      <Col xs={12} md={5} lg={8}>
                        <CurrencyInput name="tax" value={tax} id="tax" onChange={this.handlePropertyExpensesChange}/>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col xs={12} md={3} lg={4}>
                        <label>Monthly Insurance</label>
                      </Col>
                      <Col xs={12} md={5} lg={8}>
                        <CurrencyInput name="insurance" value={insurance} id="insurance" onChange={this.handlePropertyExpensesChange}/>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col xs={12} md={3} lg={4}>
                        <label>Monthly HOA</label>
                      </Col>
                      <Col xs={12} md={5} lg={8}>
                        <CurrencyInput name="hoa" value={hoa} id="hoa" onChange={this.handlePropertyExpensesChange}/>
                      </Col>
                    </Row>
                  </div> : null }
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col xs={12} md={9} lg={6}>
                  <label className="property-features" onClick={()=> this.handleClickExpandCollapse('propertyFeatures')}>
                    Property Features
                    { expandPropertyFeatures ? <RemoveIcon className="collapse-icon"/> : <AddIcon className="expand-icon"/> }
                  </label>
                </Col>
              </Row>
              { expandPropertyFeatures ? <Row>
                <Col xs={12} md={9} lg={6}>
                  <div className="indent-box">
                    <Row className="align-items-center">
                      <Col xs={12} md={3} lg={4}>
                        <label>Bed</label>
                      </Col>
                      <Col xs={12} md={5} lg={8}>
                        <NumberInput name="bed" value={bed} id="bed" onChange={this.handlePropertyInfoChange} />
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col xs={12} md={3} lg={4}>
                        <label>Bath</label>
                      </Col>
                      <Col xs={12} md={5} lg={8}>
                        <NumberInput name="bath" value={bath} id="bath" onChange={this.handlePropertyInfoChange}/>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col xs={12} md={3} lg={4}>
                        <label>Living square feet</label>
                      </Col>
                      <Col xs={12} md={5} lg={8}>
                        <NumberInput name="livingSquareFeet" value={livingSquareFeet} id="livingSquareFeet" onChange={this.handlePropertyInfoChange}/>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col xs={12} md={3} lg={4}>
                        <label>Zip Code</label>
                      </Col>
                      <Col xs={12} md={5} lg={8}>
                        <ZipcodeInput name="zipCode" value={zipCode} id="zipCode" onChange={this.handlePropertyInfoChange}/>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row> : null }
            </Fragment> : 
            <Row className="align-items-center">
              <Col xs={12} md={4} lg={2}>
                <label>What is the monthly rent you pay for your primary residence?</label>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <CurrencyInput value={rentPrimaryResidence} name="rentPrimaryResidence" id="rentPrimaryResidence" onChange={this.handleAssumptionsChange} />
              </Col>
            </Row>
          }
          <Row className="align-items-center">
            <Col xs={12} md={12} lg={12}>
              <label>Do you want to buy a new property to use it as?</label>
              <div className="custom-control custom-radio inline ml-3">
                <input 
                  type="radio" 
                  className="custom-control-input" 
                  id="useItAsPrimary" 
                  name="useItAs"
                  checked={propertyUseItAs === 'primary'}
                  onChange={() => this.handleClickUseItAs('primary')} />
                <label className="custom-control-label" htmlFor="useItAsPrimary">Primary Residence</label>
              </div>
              <div className="custom-control custom-radio inline">
                <input 
                  type="radio" 
                  className="custom-control-input" 
                  id="useItAsSecond" 
                  name="useItAs"
                  checked={propertyUseItAs === 'second'}
                  onChange={() => this.handleClickUseItAs('second')} />
                <label className="custom-control-label" htmlFor="useItAsSecond">Second Home / Vacation Home</label>
              </div>
              <div className="custom-control custom-radio inline">
                <input 
                  type="radio" 
                  className="custom-control-input" 
                  id="useItAsRental" 
                  name="useItAs"
                  checked={propertyUseItAs === 'rental'}
                  onChange={() => this.handleClickUseItAs('rental')} />
                <label className="custom-control-label" htmlFor="useItAsRental">Rental Property</label>
              </div>
            </Col>
          </Row>
          { propertyUseItAs === 'rental' ? <Row className="align-items-center">
            <Col xs={12} md={4} lg={2}>
              <label>What is your new estimated rental income?</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <CurrencyInput value={estimatedRentalIncome} name="estimatedRentalIncome" id="estimatedRentalIncome" onChange={this.handleAssumptionsChange} />
            </Col>
          </Row> : null }
          <Row className="align-items-center">
            <Col xs={12} md={4} lg={2}>
              <label>Down payment</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <CurrencyInput value={downPayment} name="downPayment" id="downPayment" onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={4} lg={2}>
              <label>Monthly salary income</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <CurrencyInput value={monthlyIncome} name="monthlyIncome" id="monthlyIncome" onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={12} lg={12}>
              <label className="monthly-debts" onClick={()=> this.handleClickExpandCollapse('personalDebt')}>
                Monthly personal debt
                { expandPersonalDebt ? <RemoveIcon className="collapse-icon"/> : <AddIcon className="expand-icon"/> }
              </label>
              { !expandPersonalDebt ? <span className="badge badge-green total">{currencyFormat(monthlyDebt)}</span> : null }
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={9} lg={6}>
              { expandPersonalDebt ? <div className="indent-box">
                <Row className="align-items-center">
                  <Col xs={12} md={5} lg={4}>
                    <label>Monthly Student loans</label>
                  </Col>
                  <Col xs={12} md={7} lg={8}>
                    <CurrencyInput value={studentLoans} name="studentLoans" id="student-loans" onChange={this.handleDebtChange} />
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={12} md={5} lg={4}>
                    <label>Monthly Car loans</label>
                  </Col>
                  <Col xs={12} md={7} lg={8}>
                    <CurrencyInput value={carLoans} name="carLoans" id="car-loans" onChange={this.handleDebtChange} />
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={12} md={5} lg={4}>
                    <label>Monthly minimum credit card due</label>
                  </Col>
                  <Col xs={12} md={7} lg={8}>
                    <CurrencyInput value={creditCardDue} name="creditCardDue" id="credit-card-due" onChange={this.handleDebtChange} />
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={12} md={5} lg={4}>
                    <label>Other monthly debts</label>
                  </Col>
                  <Col xs={12} md={7} lg={8}>
                    <CurrencyInput value={otherDebts} name="otherDebts" id="other-debts" onChange={this.handleDebtChange} />
                  </Col>
                </Row>
              </div> : null }
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={9} lg={6}>
              <label className="other-property" onClick={()=> this.handleClickExpandCollapse('otherProperty')}>
                Please add second / vacation home expenses
                { expandOtherProperty ? <RemoveIcon className="collapse-icon"/> : <AddIcon className="expand-icon"/> }
              </label>
            </Col>
          </Row>
          {expandOtherProperty ? (otherProperties || []).map((item, index) => {
            return (
              <Row className="align-items-center" key={index}>
                <Col xs={12} md={9} lg={6}>
                  <div className={'other-property-wrapper' + (index + 1 < otherProperties.length ? ' delete' : '')}>
                    <div className="other-property-title" >
                      { index + 1 === otherProperties.length 
                        ? <Button className="btn-property" onClick={this.handleAddOtherProperty}>+</Button>  
                        : null }
                      { index + 1 < otherProperties.length 
                        ? <Button className="btn-property" onClick={() => this.handleDeleteOtherProperty(index)}>x</Button>  
                        : null }
                    </div>
                    <div className="other-property-form-wrapper">
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Monthly Mortgage</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="mortgage" value={item.mortgage} index={index} id="mortgage" onChange={this.handleOtherPropertyChange} />
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Monthly Tax</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="tax" value={item.tax} index={index} id="tax" onChange={this.handleOtherPropertyChange}/>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Monthly Insurance</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="insurance" value={item.insurance} index={index} id="insurance" onChange={this.handleOtherPropertyChange}/>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Monthly HOA</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="hoa" value={item.hoa} index={index} id="hoa" onChange={this.handleOtherPropertyChange}/>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Bed</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <NumberInput name="bed" value={item.bed} index={index} id="bed" onChange={this.handleOtherPropertyChange} />
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Bath</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <NumberInput name="bath" value={item.bath} index={index} id="bath" onChange={this.handleOtherPropertyChange}/>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Living square feet</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <NumberInput name="livingSquareFeet" value={item.livingSquareFeet} index={index} id="livingSquareFeet" onChange={this.handleOtherPropertyChange}/>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Zip Code</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <ZipcodeInput name="zipCode" value={item.zipCode} index={index} id="zipCode" onChange={this.handleOtherPropertyChange}/>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          }) : null}
          <Row className="align-items-center">
            <Col xs={12} md={9} lg={6}>
              <label className="existing-property" onClick={()=> this.handleClickExpandCollapse('existingProperty')}>
                Existing Net Rental Income or Loss for all properties:
                { expandExistingProperty ? <RemoveIcon className="collapse-icon"/> : <AddIcon className="expand-icon"/> }
              </label>
            </Col>
          </Row>
          {expandExistingProperty ? (existingProperties || []).map((item, index) => {
            return (
              <Row className="align-items-center" key={index}>
                <Col xs={12} md={9} lg={6}>
                  <div className={'other-property-wrapper' + (index + 1 < existingProperties.length ? ' delete' : '')}>
                    <div className="other-property-title" >
                      { index + 1 === existingProperties.length 
                        ? <Button className="btn-property" onClick={this.handleAddExistingProperty}>+</Button>  
                        : null }
                      { index + 1 < existingProperties.length 
                        ? <Button className="btn-property" onClick={() => this.handleDeleteExistingProperty(index)}>x</Button>  
                        : null }
                    </div>
                    <div className="other-property-form-wrapper">
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Ask to enter rent</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="rent" value={item.rent} index={index} id="rent" onChange={this.handleExistingPropertyChange} />
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Monthly Mortgage</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="mortgage" value={item.mortgage} index={index} id="mortgage" onChange={this.handleExistingPropertyChange} />
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Monthly Tax</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="tax" value={item.tax} index={index} id="tax" onChange={this.handleExistingPropertyChange} />
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Monthly Insurance</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="insurance" value={item.insurance} index={index} id="insurance" onChange={this.handleExistingPropertyChange} />
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Monthly HOA</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="hoa" value={item.hoa} index={index} id="hoa" onChange={this.handleExistingPropertyChange} />
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs={12} md={3} lg={4}>
                          <label>Monthly expenses filed on Tax return</label>
                        </Col>
                        <Col xs={12} md={5} lg={8}>
                          <CurrencyInput name="filedOnTax" value={item.filedOnTax} index={index} id="filedOnTax" onChange={this.handleExistingPropertyChange} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          }) : null}
          <Row className="align-items-center">
            <Col xs={12} md={4} lg={2}>
              <label>30-Year fixed rate</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <RateInput label="" value={rate} onChange={this.handleAssumptionsChange}/>
            </Col>
          </Row>
          {this.props.purchasePower ? <Row className="align-items-center">
            <Col xs={12} md={9} lg={6}>
              <Table bordered className="purchase-power-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Easy Qualified</th>
                    <th>Max Qualified</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><label>Loan Amount</label></td>
                    <td><span className="badge badge-green">{currencyFormat(this.props.purchasePower.easyQualified.loanAmount)}</span></td>
                    <td><span className="badge badge-red">{currencyFormat(this.props.purchasePower.maxQualified.loanAmount)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Purchase Power</label></td>
                    <td><span className="badge badge-green">{currencyFormat(this.props.purchasePower.easyQualified.purchasePower)}</span></td>
                    <td><span className="badge badge-red">{currencyFormat(this.props.purchasePower.maxQualified.purchasePower)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Monthy payment</label></td>
                    <td><span className="badge badge-green">{currencyFormat(this.props.purchasePower.easyQualified.monthlyPayment)}</span></td>
                    <td><span className="badge badge-red">{currencyFormat(this.props.purchasePower.maxQualified.monthlyPayment)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Tax/ Insurance</label></td>
                    <td><span className="badge badge-green">{currencyFormat(this.props.purchasePower.easyQualified.taxInsurance)}</span></td>
                    <td><span className="badge badge-red">{currencyFormat(this.props.purchasePower.maxQualified.taxInsurance)}</span></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row> : null }
          { mode === 'debug' ? <Row className="align-items-center">
            <Col xs={12} md={9} lg={6}>
              <Table bordered className="result-table">
                <tbody>
                  <tr>
                    <td><label>Total net rental existing properties</label></td>
                    <td><span className="badge badge-red">{currencyFormat(netRentalIncomeExistingProperties)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Personal Debt</label></td>
                    <td><span className="badge badge-green">{currencyFormat(personalDebt)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Property Debt</label></td>
                    <td><span className="badge badge-green">{currencyFormat(propertyDebt)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Other Property Debt</label></td>
                    <td><span className="badge badge-green">{currencyFormat(otherPropertyDebt)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Total Debt</label></td>
                    <td><span className="badge badge-red">{currencyFormat(totalDebt)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Personal Income</label></td>
                    <td><span className="badge badge-green">{currencyFormat(personalIcome)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Property Income</label></td>
                    <td><span className="badge badge-green">{currencyFormat(propertyIcome)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Total Income</label></td>
                    <td><span className="badge badge-red">{currencyFormat(totalIncome)}</span></td>
                  </tr>
                  <tr>
                    <td><label>New Rental Income</label></td>
                    <td><span className="badge badge-red">{currencyFormat(newRentalIncome)}</span></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row> : null}
        </Container>
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {
    purchasePower: state.calculator.purchasePower,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    calculatePurchasePower: (totalIncome, totalDebt, downPayment, rate, newRentalIncome) => dispatch(calculatePurchasePower(totalIncome, totalDebt, downPayment, rate, newRentalIncome)),
  };
};

PurchasePower.propTypes = {
  calculatePurchasePower: PropTypes.func,
  purchasePower: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchasePower));
