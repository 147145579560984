import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import RateCalculator from './RateCalculator';
import RateComparisonResult from './RateComparisonResult';
import RateComparisonTableChart from './RateComparisonTableChart';

class RateComparison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assumptions1: {
        loanAmount: 400000,
        year: 30,
        rate: 3.5,
      },
      assumptions2: {
        loanAmount: 400000,
        year: 30,
        rate: 3,
      },
      result: [],
      paymentChart: false,
      paymentTable: false,
      isCompare: false,
    };
    this.handleResultChange = this.handleResultChange.bind(this);
    this.handleClickPaymentChart = this.handleClickPaymentChart.bind(this);
    this.handleClickPaymentTable = this.handleClickPaymentTable.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { assumptions } = this.props;
    if (assumptions && assumptions.length && prevProps.assumptions !== assumptions) {
      this.handleAssumptionsFetched(assumptions);
    }
  }

  handleAssumptionsFetched(assumptions) {
    if (assumptions.length) {
      const assumptions1 = assumptions[0];
      const assumptions2 = assumptions[1];
      this.setState(prevState => ({
        ...prevState,
        isCompare: true,
        assumptions1,
        assumptions2
      }));
    }
  }

  handleResultChange(assumptions, result) {
    const assumptionName = `assumptions${assumptions.index + 1}`;
    if (!this.state.isCompare) {
      this.setState(prevState => ({
        ...prevState,
        [assumptionName]: {
          ...prevState[assumptionName],
          ...assumptions.assumptions
        },
        result
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        result
      }));
    }
  }

  handleClickPaymentChart() {
    this.setState(prevState => ({
      ...prevState,
      paymentChart: !prevState.paymentChart,
    }));
  }
  
  handleClickPaymentTable() {
    this.setState(prevState => ({
      ...prevState,
      paymentTable: !prevState.paymentTable,
    }));
  }

  render() {
    return (
      <section className="rate-comparison-wrapper">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h5 className="page-title">Rate Comparison</h5>
            </Col>
          </Row>
          <Row className="rate-comparison-assumptions">
            <Col className="rate-comparison-assumptions-input" xs={4} md={4} lg={3}>
              <h6>Program 1</h6>
              <RateCalculator isCompare={this.state.isCompare} assumptions={this.state.assumptions1} index={0} onResultFetched={this.handleResultChange} float="right"/>
            </Col>
            <Col className="rate-comparison-assumptions-label" xs={4} md={4} lg={2}>
              <Row>
                <Col><label>Loan Amount</label></Col>
              </Row>
              <Row>
                <Col><label>Year</label></Col>
              </Row>
              <Row>
                <Col><label>Rate</label></Col>
              </Row>
            </Col>
            <Col className="rate-comparison-assumptions-input" xs={4} md={4} lg={3}>
              <h6>Program 2</h6>
              <RateCalculator isCompare={this.state.isCompare} assumptions={this.state.assumptions2} index={1} onResultFetched={this.handleResultChange}/>
            </Col>
          </Row>
          <Row className="rate-comparison-result">
            <Col className="rate-comparison-result-content text-right" xs={4} md={4} lg={3}>
              {this.state.result && this.state.result.length 
                ? <RateComparisonResult mortgageResult={this.state.result[0]} /> 
                : null}
            </Col>
            <Col className="rate-comparison-result-label" xs={4} md={4} lg={2}>
              <Row>
                <Col><label>Monthly Paid Month</label></Col>
              </Row>
              <Row>
                <Col><label>Total Interest Paid</label></Col>
              </Row>
              <Row>
                <Col><label>Saving</label></Col>
              </Row>
            </Col>
            <Col className="rate-comparison-result-content text-left" xs={4} md={4} lg={3}>
              {this.state.result && this.state.result.length 
                ? <RateComparisonResult mortgageResult={this.state.result[1]} /> 
                : null}
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-3">
            <Col xs={12} md={6} lg={8}>
              <label className="custom-checkbox inline" id={`check-payment-chart-${Date.now()}`}>Payment Chart
                <input type="checkbox" onChange={this.handleClickPaymentChart}/>
                <span className="checkmark"></span>
              </label>
              <label className="custom-checkbox inline" id={`check-payment-table-${Date.now()}`}>Payment Table
                <input type="checkbox" onChange={this.handleClickPaymentTable}/>
                <span className="checkmark"></span>
              </label>
            </Col>
          </Row>
          <Row className="rate-comparison-result-table-chart">
            <Col xs={12} md={6} lg={6}>
              {this.state.result && this.state.result.length 
                ? <RateComparisonTableChart paymentChart={this.state.paymentChart} paymentTable={this.state.paymentTable} mortgageResult={this.state.result[0]}/> 
                : null}
            </Col>
            <Col xs={12} md={6} lg={6}>
              {this.state.result && this.state.result.length 
                ? <RateComparisonTableChart paymentChart={this.state.paymentChart} paymentTable={this.state.paymentTable} mortgageResult={this.state.result[1]}/> 
                : null}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

RateComparison.propTypes = {
  onLoanAmountFetched: PropTypes.func,
  assumptions: PropTypes.array,
};

export default RateComparison;