import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SHOW_SPINNER,
  HIDE_SPINNER,
  LOGOUT
} from './types';
import { showErrorAlert } from './alert.action';
import { AuthenticateService } from './../services';
import { history } from '../helpers';

export const register = (firstName, lastName, phoneNumber, email, password) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    AuthenticateService.register(firstName, lastName, phoneNumber, email, password)
      .then(response => {
        if (response.status === 200) {
          dispatch({type: HIDE_SPINNER});
          dispatch({
            type: REGISTER_SUCCESS,
            data: response.data
          });
          history.push('/login?createdSuccessfully=true');
        } else {
          dispatch({type: HIDE_SPINNER});
          showErrorAlert(dispatch, 'Failed operation');
        }
      })
      .catch((error) => {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: REGISTER_FAILURE,
          data: (error.response.data.error) || ''
        });
      });
  };
};

export const login = (username, password) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    AuthenticateService.login(username, password)
      .then(response => {
        if (response.status === 200) {
          dispatch({type: HIDE_SPINNER});
          dispatch({
            type: LOGIN_SUCCESS,
            data: response.data
          });
          localStorage.setItem('user', JSON.stringify(response.data));
          history.push('/');
        } else {
          dispatch({type: HIDE_SPINNER});
          showErrorAlert(dispatch, 'Failed operation');
        }
      })
      .catch((error) => {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: LOGIN_FAILURE,
          data: (error.response.data.error) || ''
        });
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({type: LOGOUT});
    localStorage.removeItem('user');
  };
};