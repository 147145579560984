import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Col, Button, Alert, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { register } from '../../actions';
class SignUpForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
      validated: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    const { firstName, lastName, phoneNumber, email, password } = this.state;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      this.setState(prevState => ({
        ...prevState,
        validated: true
      }));
    } else {
      const haveCountryCode = (/^\+1/).test(phoneNumber);
      const usPhoneNumber = haveCountryCode ? '' : `+1${phoneNumber}`;
      this.props.register(firstName, lastName, usPhoneNumber, email, password);
    }
  }

  renderSignUpForm() {
    const { message } = this.props;
    return (
      <Fragment>
        { (message) ? <Alert variant={'danger'}>{message}</Alert> : null}
        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} className="signup-form">
          <Form.Row>
            <Form.Group as={Col} controlId="first-name">
              <Form.Label>First Name <span className="required-text">*</span></Form.Label>
              <Form.Control type="text" name="firstName" onChange={this.handleChange} required/>
              <Form.Control.Feedback type="invalid">First name is required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="last-name">
              <Form.Label>Last Name <span className="required-text">*</span></Form.Label>
              <Form.Control type="text" name="lastName" onChange={this.handleChange} required/>
              <Form.Control.Feedback type="invalid">Last name is required</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="phone">
            <Form.Label>Phone number <span className="required-text">*</span></Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>+1</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="number" name="phoneNumber" onChange={this.handleChange} required/>
              <Form.Control.Feedback type="invalid">Phone number is required</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email address <span className="required-text">*</span></Form.Label>
            <Form.Control type="email" name="email" onChange={this.handleChange} required/>
            <Form.Control.Feedback type="invalid">Email address is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} controlId="password">
              <Form.Label>Password <span className="required-text">*</span></Form.Label>
              <Form.Control type="password" name="password" onChange={this.handleChange} required/>
              <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback> 
            </Form.Group>
          </Form.Row>
          <Button variant="primary" type="submit" className="w-100">
            Register
          </Button>
        </Form>
        <p className="link-wrapper text-center">Already have an account? <Link to="/login">Sign in</Link></p>
      </Fragment>
    );
  }

  render() {
    return this.renderSignUpForm();
  }

}

const mapStateToProps = state => {
  return {
    message: state.authenticate.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (firstName, lastName, phoneNumber, email, password) => dispatch(register(firstName, lastName, phoneNumber, email, password)),
  };
};

SignUpForm.propTypes = {
  register: PropTypes.func,
  message: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpForm));
