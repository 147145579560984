import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import YearInput from '../../Controls/InputYear';
import RateInput from '../../Controls/InputRate';
import LoanAmountInput from '../../Controls/InputLoanAmount';

import { comparisonRate } from '../../../actions';

class RateCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.props.index,
      assumptions: {
        loanAmount: this.props.assumptions.loanAmount,
        year: this.props.assumptions.year,
        rate: this.props.assumptions.rate,
      },
      isCompare: false,
    };
    this.handleAssumptionsChange = this.handleAssumptionsChange.bind(this);
    this.mortgageCalculator = this.mortgageCalculator.bind(this);
  }

  componentDidMount() {
    this.mortgageCalculator();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rateComparison !== this.props.rateComparison) {
      this.props.onResultFetched(this.state, this.props.rateComparison);
    }

    if (prevState.isCompare !== this.props.isCompare) {
      this.setState(prevState => ({
        ...prevState,
        isCompare: this.props.isCompare,
        assumptions: this.props.assumptions
      }), () => this.mortgageCalculator());
    }
  }

  handleAssumptionsChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      assumptions: {
        ...prevState.assumptions,
        [fieldName]: value,
      }
    }), () => this.mortgageCalculator());
  }

  mortgageCalculator() {
    this.props.comparisonRate(this.state.assumptions.loanAmount, this.state.assumptions.year, this.state.assumptions.rate, 0, this.state.index);
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Col>
            <LoanAmountInput 
              float={this.props.float === 'right' ? 'right' : 'left'}
              value={this.state.assumptions.loanAmount} 
              onChange={this.handleAssumptionsChange} 
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <YearInput 
              float={this.props.float === 'right' ? 'right' : 'left'}
              value={this.state.assumptions.year} 
              onChange={this.handleAssumptionsChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RateInput 
              float={this.props.float === 'right' ? 'right' : 'left'}
              value={this.state.assumptions.rate} 
              onChange={this.handleAssumptionsChange}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }s
}

const mapStateToProps = state => {
  return {
    rateComparison: state.calculator.rateComparison,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    comparisonRate: (loanAmount, years, rate, monthlyOverpayment, index) => dispatch(comparisonRate(loanAmount, years, rate, monthlyOverpayment, index)),
  };
};

RateCalculator.propTypes = {
  comparisonRate: PropTypes.func,
  onResultFetched: PropTypes.func,
  rateComparison: PropTypes.array,
  index: PropTypes.number,
  float: PropTypes.string,
  assumptions: PropTypes.object,
  isCompare: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RateCalculator));