import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoginForm from '../../components/Login/Form';
import Image from 'react-bootstrap/Image';
import Logo from '../../assets/images/logo.png';
import { logout } from '../../actions';
import './style.scss';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.props.logout();
  }

  render() {
    const { location } = this.props;
    const { search } = location;
    const isCreatedSuccessfully = new URLSearchParams(search).get('createdSuccessfully');
    return (
      <div className="login-wrapper">
        <Image src={Logo} className="logo" />
        <h3 className="title">Login to Your Account</h3>
        <LoginForm isCreatedSuccessfully={isCreatedSuccessfully === 'true' ? true : false}/>
      </div>
    );
  }
}

const mapStateToProps = {
  logout: logout
};

LoginContainer.propTypes = {
  logout: PropTypes.func, 
  location: PropTypes.object, 
};

export default withRouter(connect(null, mapStateToProps)(LoginContainer));