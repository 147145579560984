import {
  GET_LENDER_LOWEST_RATE,
  GET_OPTIMIZE_RATES
} from '../actions/types';
    
const INITIAL_STATE = { 
  lenders: [],
  lowsetRateLenders: [],
  rateOptimize: [],
};
    
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LENDER_LOWEST_RATE:
      return {
        ...state,
        lenders: action.lenders,
        lowsetRateLenders: action.lenderSelected,
      };
    case GET_OPTIMIZE_RATES:
      return {
        ...state,
        rateOptimize: action.data
      };
    default:
      return state;
  }
};