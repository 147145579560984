import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Quote from './Quote';
import RateComparisonModal from './ComparisonModal';
import './style.scss';

import { getQuoteOptimize } from '../../actions';
class QuoteTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showComparisonButton: false,
      showComparison: false,
      showOptimizeRates: false,
      adjustmentDetails: {
        ltv: 0,
        closingCost: 0,
        baseRate: 0,
        basePrice: 0,
        adjustments: [],
      },
      selected: {},
      rateComparison: [],
    };
    this.onClickOptimizeRate = this.onClickOptimizeRate.bind(this);
    this.onClickRateComparison = this.onClickRateComparison.bind(this);
    this.handleQuoteSelected = this.handleQuoteSelected.bind(this);
    this.handleHiddenModal = this.handleHiddenModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.quote !== this.props.quote) {
      this.setState(prevState => ({
        ...prevState,
        showOptimizeRates: false,
      }));
    }
  }

  onClickOptimizeRate() {
    const assumptions = this.props.assumptions;
    this.props.getQuoteOptimize(assumptions);
    this.setState(prevState => ({
      ...prevState,
      showOptimizeRates: true,
    }));
  }

  onClickRateComparison() {
    let { rateComparison } = this.state;
    const { assumptions } = this.props;
    const { loanTerm } = assumptions;
    const loanTermYear = loanTerm.match(/\d+/)[0];
    if (rateComparison.length) {
      rateComparison = rateComparison.map(rate => {
        const x = rate;
        x.loanTerm = +loanTermYear;
        return x;
      });
      this.setState(prevState => ({
        ...prevState,
        showComparison: true,
        rateComparison,
      }));
    }
  }

  handleQuoteSelected(quotes, rateType) {
    let { selected } = this.state;
    if (!rateType) {
      selected = [];
    }
    if (quotes && quotes.length) {
      selected[rateType] = quotes;
    }
    const arrSelected = [].concat.apply([], Object.values(selected));
    this.setState(prevState => ({
      ...prevState,
      selected,
      showComparisonButton: (arrSelected.length >= 2) ? true : false,
      rateComparison: arrSelected,
    }));
  }

  handleHiddenModal() {
    this.setState(prevState => ({
      ...prevState,
      showComparison: false,
    }));
  }

  renderQuoteTable() {
    let result;
    const { showComparisonButton } = this.state;
    if(this.props.quote && this.props.quote.length) {
      result = (
        <Quote 
          rateType="default"
          quotes={ this.props.quote } 
          onQuoteSelected={this.handleQuoteSelected}
          isDisabledCheckBox={showComparisonButton}
        />
      );
    } else {
      result = (
        <Alert variant="warning" className="text-center">
          Unfortunately, we don&apos;t offer any financing options for your loan criteria.
        </Alert>
      );
    }
    return result;
  }

  renderQuoteOptimizeTable() {
    const { quoteOptimize } = this.props;
    const { showComparisonButton } = this.state;
    let result = [];
    if (quoteOptimize) {
      for (let property in quoteOptimize) {
        if (Object.prototype.hasOwnProperty.call(quoteOptimize, property)) {
          result.push(
            <Quote 
              quotes={quoteOptimize[property]} 
              key={property} 
              rateType={property} class={property || ''} 
              onQuoteSelected={this.handleQuoteSelected}
              isDisabledCheckBox={showComparisonButton}
            />
          );
        }
      }
    }
    return result;
  }

  render() {
    const { showOptimizeRates, showComparisonButton, showComparison, rateComparison } = this.state;
    return (
      <Fragment>
        <Row className="justify-content-md-center">
          <Col xs="12" md="12" lg="12" className="comparison-btn-wrapper">
            <h6>
              Mark two checkboxes to compare two rates
            </h6>
            <Button 
              className="btn-comparison"
              variant="primary"
              disabled={!showComparisonButton}
              onClick={this.onClickRateComparison}
            >
              <CompareArrowsIcon/> Rate Comparison
            </Button>
          </Col>
        </Row>
        { this.renderQuoteTable() }
        { !showOptimizeRates ? <Row className="justify-content-md-center">
          <Col xs="12" md="12" lg="12" className="text-center">
            <Button 
              className="btn-optimize"
              variant="primary"
              onClick={this.onClickOptimizeRate}
            >
              Optimize My Rate <ExpandMoreIcon/>
            </Button>
          </Col>
        </Row> : null }
        { showOptimizeRates ? this.renderQuoteOptimizeTable() : null }
        { showComparison ? <RateComparisonModal isShow={showComparison} rateComparison={rateComparison} hiddenModal={this.handleHiddenModal} /> : null }
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    quoteOptimize: state.quote.quoteOptimize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuoteOptimize: (assumptions) => dispatch(getQuoteOptimize(assumptions)),
  };
};


QuoteTable.propTypes = {
  getQuoteOptimize: PropTypes.func,
  quote: PropTypes.array,
  assumptions: PropTypes.object,
  quoteOptimize: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuoteTable));
