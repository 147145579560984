import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LoanAmountInput from '../../Controls/InputLoanAmount';
import YearInput from '../../Controls/InputYear';
import RateInput from '../../Controls/InputRate';
import MonthlyOverpaymentInput from '../../Controls/InputMonthlyOverpayment';
import MonthInput from '../../Controls/InputMonth';
import AmountInput from '../../Controls/InputAmount';
import MortgageCalculatorResult from '../MortgageCalculator/MortgageCalculatorResult';
import './style.scss';

import { mortgageCalculator } from '../../../actions';

class SmartInterestReduction extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: 400000,
      year: 30,
      rate: 3,
      monthlyOverpayment: 0,
      overpayments: [
        { year: 0, month: 1, amount: 0 },
      ],
      advanced: false,
    };
    this.mortgageCalculator = this.mortgageCalculator.bind(this);
    this.handleAssumptionsChange = this.handleAssumptionsChange.bind(this);
    this.handleOverpaymentsChange = this.handleOverpaymentsChange.bind(this);
    this.handleClickAdvanced = this.handleClickAdvanced.bind(this);
    this.handleAddOverpayment = this.handleAddOverpayment.bind(this);
    this.handleDeleteOverpayment = this.handleDeleteOverpayment.bind(this);
  }

  componentDidMount() {
    this.mortgageCalculator();
  }

  handleAssumptionsChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }), () => this.mortgageCalculator());
  }

  handleOverpaymentsChange(value, fieldName, index) {
    const overpayments = this.state.overpayments;
    if(index >= 0) {
      overpayments[index] = { ...overpayments[index], [fieldName]: value || 0 };
    }
    this.setState(prevState => ({
      ...prevState,
      overpayments
    }), () => this.mortgageCalculator());
  }

  handleAddOverpayment() {
    this.setState(prevState => ({
      ...prevState,
      overpayments: [
        ...prevState.overpayments,
        { year: 0, month: 1, amount: 0 },
      ]
    }));
  }

  handleDeleteOverpayment(index) {
    const overpayments = [...this.state.overpayments];
    if(index >= 0) {
      overpayments.splice(index, 1);
      this.setState(prevState => ({
        ...prevState,
        overpayments
      }), () => this.mortgageCalculator());
    }
  }

  handleClickAdvanced() {
    this.setState(prevState => ({
      ...prevState,
      advanced: !prevState.advanced,
    }), () => this.mortgageCalculator());
  }

  mortgageCalculator() {
    const { loanAmount, year, rate, monthlyOverpayment, advanced, overpayments } = this.state;
    this.props.mortgageCalculator(
      loanAmount,
      year,
      rate,
      monthlyOverpayment,
      advanced ? overpayments : []
    );
  }

  render() {
    return (
      <section className="smart-interest-reduction-wrapper">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h5 className="page-title">Smart Interest Reduction</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h6>Enter your loan info:</h6>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={2} lg={1}>
              <label>Amount</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <LoanAmountInput label="" value={this.state.loanAmount} onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={2} lg={1}>
              <label>Years</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <YearInput label="" value={this.state.year} onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={2} lg={1}>
              <label>Rate</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <RateInput label="" value={this.state.rate} onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <h6>Enter monthly additional payment to save more:</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={5}>
              <MonthlyOverpaymentInput value={this.state.monthlyOverpayment} onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={9} lg={6}>
              <h6 className="expand-title" onClick={this.handleClickAdvanced}>Advanced payment {this.state.advanced ? <RemoveIcon className="collapse-icon"/> : <AddIcon className="expand-icon"/>}</h6>
              {this.state.advanced ? <div className="overpayment-wrapper indent-box">
                <h6>Add overpayment at specific time</h6>
                {(this.state.overpayments || []).map((item, index) => {
                  return (
                    <Row key={index}>
                      <Col xs={6} md={3} lg={3}>
                        <YearInput label="Year" value={item.year} index={index} onChange={this.handleOverpaymentsChange} />
                      </Col>
                      <Col xs={6} md={3} lg={3}>
                        <MonthInput label="Month" value={item.month} index={index} onChange={this.handleOverpaymentsChange}/>
                      </Col>
                      <Col xs={10} md={4} lg={4}>
                        <AmountInput label="Amount" value={item.amount} index={index} onChange={this.handleOverpaymentsChange} />
                      </Col>
                      <Col className="overpayment-button" xs={2} md={2} lg={2}>
                        { index + 1 === this.state.overpayments.length 
                          ? <Button variant="primary" className="btn-action btn-overpayment" onClick={this.handleAddOverpayment}>+</Button> 
                          : null }
                        { index + 1 < this.state.overpayments.length 
                          ? <Button variant="primary" className="btn-delete btn-overpayment" onClick={() => this.handleDeleteOverpayment(index)}>x</Button> 
                          : null }
                      </Col>
                    </Row>
                  );
                })}
              </div> : null }
            </Col>
          </Row>
        </Container>
        {this.props.mortgageResult && Object.keys(this.props.mortgageResult).length > 0 
          ? <MortgageCalculatorResult mortgageResult={this.props.mortgageResult} /> 
          : null}
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {
    mortgageResult: state.calculator.mortgageResult,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mortgageCalculator: (loanAmount, years, rate, monthlyOverpayment, overpayments) => dispatch(mortgageCalculator(loanAmount, years, rate, monthlyOverpayment, overpayments)),
  };
};

SmartInterestReduction.propTypes = {
  mortgageCalculator: PropTypes.func,
  mortgageResult: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SmartInterestReduction));