import {
  GET_QUOTE_SUCCESS,
  GET_QUOTE_OPTIMIZE_SUCCESS,
} from '../actions/types';
  
const INITIAL_STATE = {
  quote: [],
};
  
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.data
      };
    case GET_QUOTE_OPTIMIZE_SUCCESS:
      return {
        ...state,
        quoteOptimize: action.data,
      };
    default:
      return state;
  }
};