import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  active: {
    color: '#35d7a9',
  },
});

function ListItemLink(props) {
  const classes = useStyles();
  return <ListItem button component={NavLink} activeClassName={classes.active} {...props} />;
}

export default ListItemLink;