import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

class MonthlyOverpaymentInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: parseFloat(this.props.value)
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(values) {
    const { formattedValue, value } = values;
    this.setState({ value: formattedValue });
    if (this.props.onChange) {
      this.props.onChange(parseFloat(value), 'monthlyOverpayment');
    }
  }

  renderMonthlyOverpaymentInput() {
    let result;
    result = (
      <Form.Group controlId="monthly">
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="monthly">$</InputGroup.Text>
          </InputGroup.Prepend>
          <NumberFormat 
            customInput={FormControl} 
            value={this.state.value} 
            thousandSeparator={true} 
            onValueChange={this.handleChange} 
          />
        </InputGroup>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderMonthlyOverpaymentInput();
  }
}

MonthlyOverpaymentInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default MonthlyOverpaymentInput;

