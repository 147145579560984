import {
  GET_LOANTYPES
} from './types';
import { showErrorAlert } from './alert.action';
import { LoanTypeService } from '../services';
  
export const getLoanTypes = () => {
  return (dispatch) => {
    LoanTypeService.getLoanTypes().then(response => {
      if (response.success) {
        dispatch({
          type: GET_LOANTYPES,
          data: response.data
        });
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};