import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

class MonthlyPaymentInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: parseFloat(this.props.value)
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if(this.props.value) {
        const value = parseFloat(this.props.value) ;
        this.setState({ value });
      }
    }
  }

  handleChange(values) {
    const { formattedValue, value } = values;
    this.setState({ value: formattedValue });
    if (this.props.onChange) {
      this.props.onChange(parseFloat(value), 'monthlyPayment');
    }
  }

  renderMonthlyPaymentInput() {
    let result;
    result = (
      <Form.Group controlId={`monthly-payment-${Date.now()}`}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="monthly-payment">$</InputGroup.Text>
          </InputGroup.Prepend>
          <NumberFormat 
            disabled={this.props.disabled}
            customInput={FormControl} 
            value={this.state.value} 
            thousandSeparator={true} 
            onValueChange={this.handleChange} 
          />
        </InputGroup>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderMonthlyPaymentInput();
  }
}

MonthlyPaymentInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default MonthlyPaymentInput;

