import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

class LoanTermSelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: (this.props.value) || '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const value = event.target.value;
    const fieldName = event.target.name;
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value, fieldName);
    }
  }

  renderLoanTermSelectBox = () => {
    return (
      <Form.Group controlId="loan-term">
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <Form.Control 
          size="sm" 
          as="select"
          name="loanTerm"
          value={this.state.value}
          onChange={this.handleChange}
        >
          <option value={'Fixed_10'}>10 Years Fixed</option>
          <option value={'Fixed_15'}>15 Years Fixed</option>
          <option value={'Fixed_20'}>20 Years Fixed</option>
          <option value={'Fixed_25'}>25 Years Fixed</option>
          <option value={'Fixed_30'}>30 Years Fixed</option>
          <option value={'ARM_5_1'}>5 Years Adjustable</option>
          <option value={'ARM_7_1'}>7 Years Adjustable</option>
          <option value={'ARM_10_1'}>10 Years Adjustable</option>
        </Form.Control>
      </Form.Group>
    );
  }

  render() {
    return this.renderLoanTermSelectBox();
  }
}

LoanTermSelectBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default LoanTermSelectBox;

