import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';
import { login } from '../../actions';
import { Link } from 'react-router-dom';

import './style.scss';

class LoginForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      validated: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    const { email, password } = this.state;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      this.setState({ validated: true });
    } else {
      this.props.login(email, password);
    }
  }

  renderLoginForm() {
    const { message, isCreatedSuccessfully } = this.props;
    return (
      <Fragment>
        { message ? <Alert variant={'danger'}>{message}</Alert> : null}
        { isCreatedSuccessfully ? <Alert variant={'success'}>Your account has been created!</Alert> : null }
        <Form className="login-form" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
          <Form.Group controlId="email">
            <Form.Control type="email" placeholder="Email" name="email" onChange={this.handleChange} required/>
            <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Control type="password" placeholder="Password" name="password" onChange={this.handleChange} required/>
            <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100">
            Submit
          </Button>
        </Form>
        <ul className="link-wrapper text-center">
          <li className="link"><Link to="/signup">Sign Up</Link></li>
          <li className="link">|</li>
          <li className="link"><Link to="#">Reset Password</Link></li>
        </ul>
      </Fragment>
    );
  }
  render() {
    return this.renderLoginForm();
  }
}

const mapStateToProps = state => {
  return {
    message: state.authenticate.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
  };
};

LoginForm.propTypes = {
  message: PropTypes.string,
  isCreatedSuccessfully: PropTypes.bool,
  login: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
