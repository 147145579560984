import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import rootReducer from './reducers/index';

import 'bootstrap/dist/css/bootstrap.css';
import './style.scss';
import App from './components/App';

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}> <App/> </Provider>,
  document.getElementById('wonderate-wrapper')
);

serviceWorker.unregister();