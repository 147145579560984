import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import { percentFormat, currencyFormat } from '../../utils';
import SpanValue from './SpanValue';
import QuoteAdjustment from './Adjustment';

import './style.scss';

class Quote extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showAdjustmentDetails: false,
      adjustmentDetails: {
        ltv: 0,
        closingCost: 0,
        baseRate: 0,
        basePrice: 0,
        adjustments: [],
      },
      selected: [],
      checked: {},
      disabledChecbox: this.props.isDisabledCheckBox,
    };
    this.onClickShowAdjustmentDetails = this.onClickShowAdjustmentDetails.bind(this);
    this.onClickCloseAdjustmentDetails = this.onClickCloseAdjustmentDetails.bind(this);
    this.onClickSelecedCompare = this.onClickSelecedCompare.bind(this);
    this.onSelectedQuote = this.onSelectedQuote.bind(this);
    this.resetSelectedRates = this.resetSelectedRates.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quotes !== this.props.quotes) {
      this.resetSelectedRates();
    }
    if (prevProps.isDisabledCheckBox !== this.props.isDisabledCheckBox) {
      this.updateCheckedCheckbox(this.props.isDisabledCheckBox);
    }
  }

  updateCheckedCheckbox(isDisabledCheckBox) {
    this.setState(prevState => ({
      ...prevState,
      disabledChecbox: isDisabledCheckBox,
    }));
  }

  resetSelectedRates() {
    const selected = [];
    const checked = {};
    this.setState(prevState => ({
      ...prevState,
      selected,
      checked
    }), () => this.onSelectedQuote(selected, null));
  }

  onClickShowAdjustmentDetails({ ltv, closingCost, baseRate, basePrice, adjustments }) {
    this.setState({
      showAdjustmentDetails: true,
      adjustmentDetails: {
        ltv,
        closingCost,
        baseRate,
        basePrice,
        adjustments,
      }
    });
  }

  onClickCloseAdjustmentDetails() {
    this.setState({
      showAdjustmentDetails: false,
    });
  }

  onClickSelecedCompare(index, rateType, quote) {
    const { selected } = this.state;
    const objSelected = {
      index,
      rateType,
      quote
    };
    if(!selected.length) {
      selected.push(objSelected);
    } else {
      const selectedIndex = selected.findIndex(x => x.index === index);
      if (selectedIndex >= 0) {
        selected.splice(selectedIndex, 1);
      } else {
        selected.push(objSelected);
      }
    }
    this.setState(prevState => ({
      ...prevState,
      selected,
      checked: {
        ...prevState.checked,
        [index]: !prevState.checked[index]
      }
    }), () => this.onSelectedQuote(selected, rateType));
  }

  onSelectedQuote(quotes, rateType) {
    this.props.onQuoteSelected(quotes, rateType);
  }
    
  render() {
    const { showAdjustmentDetails, adjustmentDetails, checked, disabledChecbox } = this.state;
    const { quotes, rateType } = this.props;
    let result = '';
    if (quotes && quotes.length) {
      const { ltv, creditScore, loanAmount } = quotes[0];
      const quotesLength = quotes.length;
      const titleColumnWidth = quotesLength * 4;
      result = (
        <Fragment>
          <Container className={`rate-table ${this.props.class}`}>
            <Row className="justify-content-md-center">
              <Col xs="12" md={titleColumnWidth} lg={titleColumnWidth}>
                <div className="rate-table-title">
                  <ul>
                    <li>Loan Amount: {currencyFormat(loanAmount)}</li>
                    <li>&nbsp;- LTV: {ltv}</li>
                    <li>&nbsp;- FICO: {creditScore}</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center rate-table-result">
              { quotes.map((item, idx) => {
                const { ltv, closingCost, baseRate, basePrice, adjustments, apr, rate, term, monthlyPayment } = item;
                return (
                  <Col key={idx} xs="12" md="4" lg="4">
                    <Card className="rate-details">
                      <Card.Header className="rate-details-loan-term">{term}</Card.Header>
                      <Card.Body className="rate-details-body">
                        <h6 className="rate-details-apr">{percentFormat(apr)} APR</h6>
                        <h2 className="rate-details-rate">{percentFormat(rate)}</h2>
                        <Button variant="primary" className="btn-apply">Apply</Button>
                        <p>Monthly payment: <span className="cost">{currencyFormat(monthlyPayment)}</span></p>
                        <p 
                          className="rate-details-closing-cost" 
                          onClick={() => this.onClickShowAdjustmentDetails({ ltv, closingCost, baseRate, basePrice, adjustments })}>
                          {closingCost <= 0 ? 'Credit Back: ' : 'Closing Cost: '}
                          <SpanValue value={closingCost} format="currency"/>
                        </p>
                        <div className="select-box">
                          <label className="container">
                            <input 
                              type="checkbox" 
                              checked={checked[idx] || false}
                              disabled={!checked[idx] && disabledChecbox}
                              onChange={() => this.onClickSelecedCompare(idx, rateType, item)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              }) }
            </Row>
          </Container>
          <Modal 
            show={showAdjustmentDetails} 
            onHide={this.onClickCloseAdjustmentDetails}
            size="lg"
            centered>
            <Modal.Body>
              <QuoteAdjustment details={adjustmentDetails}/>
            </Modal.Body>
          </Modal>
        </Fragment>
      );
    }
    return result;
  }
}

Quote.propTypes = {
  quotes: PropTypes.array,
  class: PropTypes.string,
  rateType: PropTypes.string,
  isDisabledCheckBox: PropTypes.bool,
  onQuoteSelected: PropTypes.func,
};

Quote.defaultProps = {
  class: ''
};

export default Quote;