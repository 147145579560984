import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { getLowestRateLenders } from '../../../actions';

class LenderLowestRateAutocomplete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lowsetRateLenders: []
    };
    this.onChangeSelections = this.onChangeSelections.bind(this);
    this.updateLowsetRateLenderState = this.updateLowsetRateLenderState.bind(this);
  }

  componentDidMount() {
    this.props.getLowestRateLenders();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.lowsetRateLenders !== this.props.lowsetRateLenders) {
      this.updateLowsetRateLenderState(this.props.lowsetRateLenders);
    }
  }

  onChangeSelections(event) {
    this.updateLowsetRateLenderState(event);
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  updateLowsetRateLenderState(lowsetRateLenders) {
    this.setState(prevState => ({
      ...prevState,
      lowsetRateLenders
    }));
  }

  render() {
    const { lenders } = this.props;
    const { lowsetRateLenders } = this.state;
    let result = '';
    if (lenders.length) {
      result = (
        <Form.Group>
          <Form.Label>Lenders</Form.Label>
          <Typeahead
            id="lowest-rate-lender"
            multiple
            labelKey="name"
            placeholder="Choose a state..."
            selected={lowsetRateLenders}
            options={lenders}
            onChange={this.onChangeSelections}
          />
        </Form.Group>
      );
    }
    return result;
  }
}

const mapStateToProps = state => {
  return {
    lenders: state.setting.lenders,
    lowsetRateLenders: state.setting.lowsetRateLenders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLowestRateLenders: () => dispatch(getLowestRateLenders())
  };
};

LenderLowestRateAutocomplete.propTypes = {
  getLowestRateLenders: PropTypes.func,
  onChange: PropTypes.func,
  lowsetRateLenders: PropTypes.array,
  lenders: PropTypes.array,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LenderLowestRateAutocomplete));