import React, { Component, Fragment } from 'react';
import SiteBreadcrumb from '../../components/Breadcrumb';
import RateComparison from '../../components/LoanAssistant/RateComparison/RateComparison';

class RateComparisonContainer extends Component {

  render() {
    const crumbs = [
      { path: '/', name: 'Home'},
      { path: '/', name: 'Rate Comparison'},
    ];
    return (
      <Fragment>
        <SiteBreadcrumb crumbs={crumbs}/>
        <RateComparison />
      </Fragment>
    );
  }
}

export default RateComparisonContainer;
