import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import { line } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import 'd3-transition';
import './chart.scss';

class MortgageCalculatorChart extends Component {
  constructor(props){
    super(props);
    const margin = { top: 20, right: 20, bottom: 20, left: 60 };
    const fullWidth = 600;
    const fullHeight = 300;
    const width = fullWidth - margin.left - margin.right;
    const height = fullHeight - margin.top - margin.bottom;
    this.state = {
      margin: margin,
      fullWidth: fullWidth,
      fullHeight: fullHeight,
      width: width,
      height: height,
    };
    this.createBarChart = this.createBarChart.bind(this);
  }

  componentDidMount() {
    this.createBarChart();
  }

  componentDidUpdate() {
    this.createBarChart();
  }

  createBarChart() {
    const node = this.node;
    const x = scaleLinear().range([0, this.state.width]);
    const y = scaleLinear().range([0, this.state.height]);
    const actual = line()
      .x((d, i) => x(i + (d.partial / 12 || 1) - 1))
      .y(d => y(d.balance));
    const baseline = line()
      .x((d, i) => x(i))
      .y(d => y(d.baseline));
    const chartEl = select(node);
    x.domain([0, this.props.mortgageChart.length - 1]);
    y.domain([this.props.mortgageChart[0].balance, 0]);
    chartEl
      .select('.x')
      .call(axisBottom(x).ticks(Math.min(this.props.mortgageChart.length, 30)));

    chartEl.select('.y').call(axisLeft(y));

    chartEl
      .select('.baseline')
      .transition()
      .attr('d', baseline(this.props.mortgageChart));
    chartEl
      .select('.actual')
      .transition()
      .attr('d', actual(this.props.mortgageChart));
  }

  render() {
    let result;
    if(this.props.mortgageChart) {
      result = (
        <div className="mortgage-chart-wrapper">
          <svg ref={node => this.node = node}
            className="mortgage-chart"
            height="100%"
            width="100%"
            viewBox={`0 0 ${this.state.fullWidth} ${this.state.fullHeight}`}
          >
            <g transform={`translate(${this.state.margin.left},${this.state.margin.top})`}>
              <g className="axis x" transform={`translate(0, ${this.state.height})`} />
              <g className="axis y" />
              <path className="line baseline" />
              <path className="line actual" />
            </g>
          </svg>
        </div>
      );
    }
    return result;
  }
}

MortgageCalculatorChart.propTypes = {
  mortgageChart: PropTypes.array,
};

export default MortgageCalculatorChart;