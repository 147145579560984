import {SHOW_ALERT} from './types';
export const showSuccessAlert = (dispatch, message) => {
  dispatch({
    type: SHOW_ALERT,
    alert: {
      showAlert: true,
      colorAlert: 'success',
      messageAlert: message
    }
  });
};

export const showErrorAlert = (dispatch, message) => {
  dispatch({
    type: SHOW_ALERT,
    alert: {
      showAlert: true,
      headingAlert: 'Oh snap! You got an error!',
      colorAlert: 'danger',
      messageAlert: message
    }
  });
};

export const showWarningAlert = (dispatch, message) => {
  dispatch({
    type: SHOW_ALERT,
    alert: {
      showAlert: true,
      colorAlert: 'warning',
      messageAlert: message
    }
  });
};
