import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  GET_QUOTE_SUCCESS,
  GET_QUOTE_OPTIMIZE_SUCCESS,
} from './types';
import { showErrorAlert } from './alert.action';
import { QuoteService } from './../services';

export const getQuote = (assumptions) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    QuoteService.getQuote(assumptions).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_QUOTE_SUCCESS,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};
  
export const getQuoteOptimize = (assumptions) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    QuoteService.getQuoteOptimize(assumptions).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_QUOTE_OPTIMIZE_SUCCESS,
          data: response.data,
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};