import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { currencyFormat } from '../../../utils';
import './table.scss';

class MortgageCalculatorTable extends Component {
  render() {
    let result;
    if(this.props.mortgageTable) {
      result = (
        <div className="mortgage-table-wrapper">
          <Table className="mortgage-table" responsive>
            <thead>
              <tr>
                <th className="text-right">Years</th>
                <th className="text-right">Interest</th>
                <th className="text-right">Overpayment</th>
                <th className="text-right">Balance</th>
              </tr>
            </thead>
            <tbody>
              {this.props.mortgageTable.rows.map((row, index) => (
                <tr key={index}>
                  {row.map((d, i) => (
                    <td align="right" key={i}>{i === 0 ? d : currencyFormat(d)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td align="right"><b>{currencyFormat(this.props.mortgageTable.interestTotal)}</b></td>
                <td align="right"><b>{currencyFormat(this.props.mortgageTable.overpaymentTotal)}</b></td>
                <td />
              </tr>
            </tfoot>
          </Table>
        </div>
      );
    }
    return result;
  }
}

MortgageCalculatorTable.propTypes = {
  mortgageTable: PropTypes.object,
};

export default MortgageCalculatorTable;