import React, {Component} from 'react';
import SignUpForm from '../../components/SignUp/Form';

import './style.scss';

class SignUpContainer extends Component {
  render() {
    return (
      <div className="signup-wrapper">
        <h3 className="title">Create an Account</h3>
        <SignUpForm />
      </div>
    );
  }
}

export default SignUpContainer;