import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Authentication.scss';

class AuthenticationLayout extends React.Component {
  render() {
    return (
      <div className="authentication-layout">
        <div className="authentication-container">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.spinner.loading,
  };
};

AuthenticationLayout.propTypes = {
  loading: PropTypes.bool, 
  children: PropTypes.any, 
};

export default withRouter(connect(mapStateToProps, null)(AuthenticationLayout));
