import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class PageTitle extends React.Component {
  
  renderTitle() {
    let result = '';
    if (this.props.title && this.props.title.length) {
      result = (
        <h5 className="title">{this.props.title}</h5>
      );
    }
    return result;
  }

  renderSubTitle() {
    let result = '';
    if (this.props.subTitle && this.props.subTitle.length) {
      result = (
        <p className="subtitle">{this.props.subTitle}</p>
      );
    }
    return result;
  }

  render() {
    return (
      <div className="page-title-wrapper">
        {this.renderTitle()}
        {this.renderSubTitle()}
      </div>
    );
  }
}

PageTitle.propTypes = {
  title: PropTypes.string, 
  subTitle: PropTypes.string, 
};
export default PageTitle;
