import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import MortgageCalculatorTable from '../MortgageCalculator/MortgageCalculatorTable';
import MortgageCalculatorChart from '../MortgageCalculator/MortgageCalculatorChart';
import './style.scss';

class RateComparisonTableChart extends Component {

  render() {
    let mortgageResult;
    if(this.props.mortgageResult && Object.keys(this.props.mortgageResult).length > 0) {
      const payments = this.props.mortgageResult.payments;
      const { mortgageTable} = this.props.mortgageResult;
      mortgageResult = (
        <Fragment>
          <Row>
            <Col>
              {this.props.paymentChart ? <MortgageCalculatorChart mortgageChart={payments} /> : null}
              {this.props.paymentTable ? <MortgageCalculatorTable mortgageTable={mortgageTable} /> : null}
            </Col>
          </Row>
        </Fragment>
      );
    }
    return mortgageResult;
  }
}

RateComparisonTableChart.propTypes = {
  mortgageResult: PropTypes.object,
  paymentChart: PropTypes.bool,
  paymentTable: PropTypes.bool,
};

export default RateComparisonTableChart;