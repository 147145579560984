import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LoanAmountInput from '../../Controls/InputLoanAmount';
import MonthlyPaymentInput from '../../Controls/InputMonthlyPayment';
import RateInput from '../../Controls/InputRate';
import YearInput from '../../Controls/InputYear';
import CurrencyInput from '../../Controls/InputCurrency';
import { currencyFormat } from '../../../utils';
import './style.scss';

import { calculateRemaining } from '../../../actions';

class RefinanceOrNot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journey: 'stage-1',
      current: {
        loanAmount: 400000,
        rate: 4,
        monthlyPayment: 2000,
      },
      new: {
        year: 30,
        rate: 3,
        closingCost: 0,
        loanAmount: 0,
        includedLoanAmount: false,
        monthlyPayment: this.props.newMonthlyPayment || 0,
      }
    };
    this.handleCurrentChange = this.handleCurrentChange.bind(this);
    this.onHandleNextStage = this.onHandleNextStage.bind(this);
    this.handleNewChange = this.handleNewChange.bind(this);
    this.handleClickIncludedLoanAmount = this.handleClickIncludedLoanAmount.bind(this);
  }

  componentDidMount() {
    this.calculator();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.newMonthlyPayment !== this.props.newMonthlyPayment) {
      this.setState(prevState => ({
        ...prevState,
        new: {
          ...prevState.new,
          monthlyPayment: this.props.newMonthlyPayment,
        }
      }));
    }
  }

  handleClickIncludedLoanAmount() {
    this.setState(prevState => ({
      ...prevState,
      new: {
        ...prevState.new,
        includedLoanAmount: !prevState.new.includedLoanAmount,
      }
    }), () => this.calculator());
  }

  handleCurrentChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      current: {
        ...prevState.current,
        [fieldName]: value,
      }
    }), () => this.calculator());
  }

  handleNewChange(value, fieldName) {
    if (fieldName === 'closingCost' && !value) {
      value = 0;
    }
    this.setState(prevState => ({
      ...prevState,
      new: {
        ...prevState.new,
        [fieldName]: value,
      }
    }), () => this.calculator());
  }

  onHandleNextStage(stage) {
    this.setState(prevState => ({
      ...prevState,
      journey: stage,
    }), () => this.calculator());
  }

  calculator() {
    const { loanAmount, monthlyPayment, rate } = this.state.current;
    const { closingCost, includedLoanAmount, year: newYear, rate: newRate } = this.state.new;
    const newLoanAmount = includedLoanAmount ? +loanAmount + +closingCost : +loanAmount;
    this.setState(prevState => ({
      ...prevState,
      new: {
        ...prevState.new,
        loanAmount: newLoanAmount,
      }
    }), () => this.props.calculateRemaining(
      loanAmount,
      monthlyPayment,
      rate,
      newYear,
      newRate,
      newLoanAmount,
    ));
  }

  render() {
    const { loanAmount: currentLoanAmount, monthlyPayment: currentMonthlyPayment, rate: currentRate } = this.state.current;
    const { rate: newRate, year: newYear, monthlyPayment: newMonthlyPayment, closingCost, includedLoanAmount, loanAmount: newLoanAmount } = this.state.new;
    
    return (
      <section className="refinance-or-not-wrapper">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h5 className="page-title">Refinance Or Not</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h6>Enter your current loan:</h6>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={4} lg={2}>
              <label>Current Loan Amount</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <LoanAmountInput value={currentLoanAmount} onChange={this.handleCurrentChange} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={4} lg={2}>
              <label>Orginal Monthly Payment</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <MonthlyPaymentInput value={currentMonthlyPayment} onChange={this.handleCurrentChange} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={4} lg={2}>
              <label>Original Interest Rate</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <RateInput value={currentRate} onChange={this.handleCurrentChange} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={9} lg={6}>
              <h6>Based on your input</h6>
              <div className="indent-box result">
                <ul className="remainings results">
                  <li className="remaining">Your remaining years: <span className="badge badge-green">{(this.props.remainingYears) ? this.props.remainingYears : 0}</span></li>
                  <li className="remaining">Your remaining interest: <span className="badge badge-green">{currencyFormat(this.props.remainingInt || 0)}</span></li>
                </ul>
              </div>
            </Col>
          </Row>
          {this.state.journey === 'stage-1' ? <Row>
            <Col xs={12} md={12} lg={12}>
              <Button variant="primary" className="next-step" onClick={() => this.onHandleNextStage('stage-2')}>Next step <ArrowForwardIosIcon /></Button>
            </Col>
          </Row> : null}
          {this.state.journey === 'stage-2' || this.state.journey === 'stage-3' ?
            <Fragment>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h6>Enter your new refinance rate:</h6>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col xs={12} md={4} lg={2}>
                  <label>Interest Rate</label>
                </Col>
                <Col xs={12} md={5} lg={4}>
                  <RateInput value={newRate} onChange={this.handleNewChange}/>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col xs={12} md={4} lg={2}>
                  <label>Year Term</label>
                </Col>
                <Col xs={12} md={5} lg={4}>
                  <YearInput value={newYear} onChange={this.handleNewChange}/>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col xs={12} md={4} lg={2}>
                  <label>Refinance Closing Cost</label>
                </Col>
                <Col xs={12} md={5} lg={4}>
                  <CurrencyInput value={closingCost} name="closingCost" id="closing-cost" onChange={this.handleNewChange} />
                </Col>
              </Row>
              { closingCost !== 0 
                ? <Row className="align-items-center">
                  <Col xs={12} md={12} lg={12}>
                    <label>Do you want to include this closing amount to the loan amount?</label>
                    <div className="custom-control custom-radio inline ml-3">
                      <input 
                        type="radio" 
                        className="custom-control-input" 
                        id="includedLoanAmountYes" 
                        name="includedLoanAmount" 
                        checked={includedLoanAmount} 
                        onChange={this.handleClickIncludedLoanAmount} />
                      <label className="custom-control-label" htmlFor="includedLoanAmountYes">Yes</label>
                    </div>
                    <div className="custom-control custom-radio inline">
                      <input 
                        type="radio" 
                        className="custom-control-input" 
                        id="includedLoanAmountNo" 
                        name="includedLoanAmount" 
                        checked={!includedLoanAmount} 
                        onChange={this.handleClickIncludedLoanAmount} />
                      <label className="custom-control-label" htmlFor="includedLoanAmountNo">No</label>
                    </div>
                  </Col>
                </Row>
                : ''}
              <Row className="align-items-center">
                <Col xs={12} md={4} lg={2}>
                  <label>New Loan Amount</label>
                </Col>
                <Col xs={12} md={5} lg={4}>
                  <LoanAmountInput value={newLoanAmount} disabled={true} />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col xs={12} md={4} lg={2}>
                  <label>New Monthly Payment</label>
                </Col>
                <Col xs={12} md={5} lg={4}>
                  <MonthlyPaymentInput value={newMonthlyPayment} disabled={true}/>
                </Col>
              </Row>
              {this.state.journey === 'stage-2' ? <Row>
                <Col xs={12} md={12} lg={12}>
                  <Button variant="primary" onClick={() => this.onHandleNextStage('stage-3')}>Calculate</Button>
                </Col>
              </Row> : null }
              {this.props.additional && this.state.journey === 'stage-3' ? <Row>
                <Col xs={12} md={9} lg={6}>
                  <div className="indent-box result">
                    <ul className="results">
                      <li>
                        Paying extra monthly <span className="badge badge-green">{currencyFormat(this.props.additional.extraMonthlyPay)}</span> to make it the same original monthly payment: <span className="badge badge-green">{currencyFormat(newMonthlyPayment + this.props.additional.extraMonthlyPay)}</span>, you will have the advantages below:
                      </li>
                      <li>
                        <ul className="additionals">
                          <li>
                            New Reduced Year Term: <span className="badge badge-green">{this.props.additional.reducedYearTerm}</span>
                          </li>
                          <li>
                            Total New Interest: <b className="badge badge-green">{currencyFormat(this.props.additional.newTotalInterest)}</b>
                          </li>
                          <li>
                            You Save: <b className="badge badge-red">{currencyFormat(this.props.additional.intSaving)}</b>
                          </li>
                          { closingCost !== 0 ? <li>
                            Because your closing cost is  {currencyFormat(closingCost)}, your final saving is <b className="badge badge-red">{currencyFormat(+this.props.additional.intSaving - +closingCost)}</b>
                          </li> : null }
                        </ul>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row> : null }
            </Fragment>
            : null
          }
        </Container>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    remainingYears: state.calculator.remainingYears,
    remainingInt: state.calculator.remainingInt,
    newMonthlyPayment: state.calculator.newMonthlyPayment,
    additional: state.calculator.additional,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    calculateRemaining: (
      remainingBalance, 
      monthlyPayment, 
      rate, 
      newTerm, 
      newRate,
      newLoanAmount
    ) => dispatch(
      calculateRemaining(
        remainingBalance, 
        monthlyPayment, 
        rate, 
        newTerm, 
        newRate,
        newLoanAmount
      )
    ),
  };
};

RefinanceOrNot.propTypes = {
  calculateRemaining: PropTypes.func,
  remainingYears: PropTypes.number,
  remainingInt: PropTypes.number,
  newMonthlyPayment: PropTypes.number,
  additional: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RefinanceOrNot));
