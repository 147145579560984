import {
  GET_MORTGAGE_CALCULATE,
  COMPARISON_RATE,
  REMAINING_MONTHS,
  PURCHASE_POWER,
} from '../actions/types';
    
const INITIAL_STATE = {
  mortgageResult: {},
  rateComparison: [],
  remainingMonths: 0,
  remainingYears: 0,
  remainingInt: 0,
};
    
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MORTGAGE_CALCULATE:
      return {
        ...state,
        mortgageResult: action.mortgageResult,
        apr: action.apr,
      };
    case REMAINING_MONTHS:
      const remainingMonths = action.remainingMonths;
      const remainingYears = Math.round(remainingMonths * 10 / 12, 1) / 10;
      const remainingInt = Math.round(action.remainingInt);
      const newMonthlyPayment =  Math.round(action.newMonthlyPayment);
      const additional =  action.additional;
      return {
        ...state,
        remainingMonths,
        remainingYears,
        remainingInt,
        newMonthlyPayment,
        additional,
      };
    case COMPARISON_RATE:
      let rateComparison = state.rateComparison;
      const index = action.index;
      let mortgage = action.data;
      const mortgageTable = mortgage.payments
        .filter((year, i) => i > 0 && (year.balance > 0 || year.interestYearly > 0))
        .reduce(
          (acc, year, index) => ({
            interestTotal: acc.interestTotal + year.interestYearly,
            overpaymentTotal: acc.overpaymentTotal + year.overpayment,
            rows: [
              ...acc.rows,
              [
                year.partial ? year.partial + 'm' : index + 1,
                year.interestYearly || 0,
                year.overpayment || 0,
                year.balance || 0,
              ]
            ]
          }),
          { interestTotal: 0, overpaymentTotal: 0, rows: [] }
        );
      mortgage.mortgageTable = mortgageTable;
      mortgage.interestTotal = mortgageTable.interestTotal;
      mortgage.overpaymentTotal = mortgageTable.overpaymentTotal;
      if (rateComparison && rateComparison.length) {
        rateComparison[index] = mortgage;
      } else {
        rateComparison = [...rateComparison, mortgage];
      }
      const objSaving = rateComparison.reduce((value, item, index) => {
        return { 
          index: (item.interestTotal < value.interestTotal) ? index : 0,
          interestTotal: item.interestTotal,
          saving: Math.abs((value.saving || 0) - item.interestTotal)
        };
      }, {});
      rateComparison = rateComparison.map((item, index) => {
        item.saving = (index === objSaving.index) ? objSaving.saving : 0;
        return item;
      });
      return {
        ...state,
        rateComparison,
      };
    case PURCHASE_POWER:
      return {
        ...state,
        purchasePower: action.data
      };
    default:
      return state;
  }
};