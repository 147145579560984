import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Icon1 from '../../assets/images/icons/icon1.svg';
import Icon2 from '../../assets/images/icons/icon2.svg';
import Icon3 from '../../assets/images/icons/icon3.svg';
import Icon4 from '../../assets/images/icons/icon4.svg';
import Icon5 from '../../assets/images/icons/icon5.svg';
import Icon6 from '../../assets/images/icons/icon6.svg';
import './style.scss';
class LoanAssistant extends Component {

  render() {
    return (
      <section className="loan-assistant-wrapper">
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center">
              <h3>Loan Assistant</h3>
              <p>(Help me choose the rate)</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={8} md={4} lg={3}>
              <Card className="loan-assistant-item">
                <Card.Body>
                  <Link to="/mortgage-calculator">
                    <Image src={Icon1} className="img" />
                    <h6 className="title">Mortgage Calculator</h6>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={8} md={4} lg={3}>
              <Card className="loan-assistant-item">
                <Card.Body>
                  <Link to="/rate-comparison">
                    <Image src={Icon2} className="img" />
                    <h6 className="title">Rate Comparison</h6>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={8} md={4} lg={3}>
              <Card className="loan-assistant-item">
                <Card.Body>
                  <Link to="/smart-interest-reduction">
                    <Image src={Icon3} className="img" />
                    <h6 className="title">Smart Interest Reduction</h6>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={8} md={4} lg={3}>
              <Card className="loan-assistant-item">
                <Card.Body>
                  <Link to="/refinance-or-not">
                    <Image src={Icon4} className="img" />
                    <h6 className="title">Refinance or Not</h6>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={8} md={4} lg={3}>
              <Card className="loan-assistant-item">
                <Card.Body>
                  <Link to="/purchase-power">
                    <Image src={Icon5} className="img" />
                    <h6 className="title">Purchase Power</h6>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={8} md={4} lg={3}>
              <Card className="loan-assistant-item">
                <Card.Body>
                  <Link to="/cosigner-or-not">
                    <Image src={Icon6} className="img" />
                    <h6 className="title">Cosign or Not</h6>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default LoanAssistant;