import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  GET_OPTIMIZE_RATES,
  GET_LENDER_LOWEST_RATE
} from './types';
import { history } from '../helpers';
import { showErrorAlert } from './alert.action';
import { SettingService } from '../services';
    
export const getLowestRateLenders = () => {
  return (dispatch) => {
    SettingService.getLowestRateLenders().then(response => {
      if (response.success) {
        const { data: lenders } = response;
        const lenderSelected = lenders.filter(lender => lender.config && lender.config.lowestRate);
        dispatch({
          type: GET_LENDER_LOWEST_RATE,
          lenders,
          lenderSelected,
        });
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const updateLowestRateLenders = (lennders, ownProps) => {
  return (dispatch) => {
    SettingService.updateLowestRateLenders(lennders).then(response => {
      if (response.success) {
        history.push(ownProps.match.path);
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getRateOptimizeListing = () => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    SettingService.getRateOptimizeListing().then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_OPTIMIZE_RATES,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const updateRateOptimize = (id, rateOptimize, ownProps) => {
  return (dispatch) => {
    SettingService.updateRateOptimize(id, rateOptimize).then(response => {
      if (response.success) {
        const { parentProps } = ownProps;
        parentProps.getRateOptimizeListing();
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

