import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup, FormControl } from 'react-bootstrap';
class RateInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: (this.props.value) || 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const value = parseFloat(this.props.value);
      this.setState({ value });
    }
  }

  handleChange(event) {
    const value = event.target.value;
    const fieldName = event.target.name;
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(parseFloat(value || 0), fieldName);
    }
  }
  
  renderRateInput = () => {
    let inputGroup = (
      <InputGroup.Prepend>
        <InputGroup.Text id="rate">%</InputGroup.Text>
      </InputGroup.Prepend>
    );
    if (this.props.float === 'right') {
      inputGroup = (
        <InputGroup.Append>
          <InputGroup.Text id="rate">%</InputGroup.Text>
        </InputGroup.Append>
      );
    }
    return (
      <Form.Group controlId={`rate-${Date.now()}`}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <InputGroup>
          {this.props.float !== 'right' ? inputGroup : null}
          <FormControl
            className={this.props.float === 'right' ? 'text-right' : 'text-left'}
            aria-label={this.props.label}
            name="rate"
            value={this.state.value}
            onChange={this.handleChange}
          />
          {this.props.float === 'right' ? inputGroup : null}
        </InputGroup>
      </Form.Group>
    );
  }

  render() {
    return this.renderRateInput();
  }
}

RateInput.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  float: PropTypes.string,
  onChange: PropTypes.func,
};

export default RateInput;

