import { combineReducers } from 'redux';
import quote from './quote.reducer';
import loanType from './loanType.reducer';
import calculator from './calculator.reducer';
import spinner from './spinner.reducer';
import authenticate from './authenticate.reducer';
import setting from './setting.reducer';

export default combineReducers({
  quote: quote,
  loanType: loanType,
  calculator: calculator,
  spinner: spinner,
  authenticate: authenticate,
  setting: setting,
});
