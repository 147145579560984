import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import SpanValue from './SpanValue';

class QuoteAdjustment extends Component {

  renderAdjustmentTable() {
    const { ltv, closingCost, baseRate, basePrice, adjustments } = this.props.details;
    const result = (
      <div className="adjustment-details-wrapper">
        <Table className="adjustment-table" responsive>
          <thead>
            <tr>
              <th>Adjustments - LTV: {ltv}</th>
              <th className="text-right">Rate</th>
              <th className="text-right">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr className="base-price">
              <td>Base Price</td>
              <td className="text-right">
                <SpanValue value={baseRate} format="percent" />
              </td>
              <td className="text-right">
                <SpanValue value={basePrice} format="currency" />
              </td>
            </tr>
            {adjustments.map((adjustment, adjustmentIndex) => {
              const { name, amount, value, valueType} = adjustment;
              let valueText = '';
              if(valueType === 'Percentage') {
                valueText = (<SpanValue value={value} format="percent" />);
              }
              return (
                <tr key={adjustmentIndex}>
                  <td>{name}</td>
                  <td className="text-right">
                    {valueText}
                  </td>
                  <td className="text-right">
                    <SpanValue value={amount} format="currency" />
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className="closing-cost">
              <td>{closingCost <= 0 ? 'Credit Back' : 'Closing Cost'}</td>
              <td className="text-right"></td>
              <td className="text-right">
                <SpanValue value={closingCost} format="currency" />
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
    return result;
  }

  render() {
    return this.renderAdjustmentTable();
  }

}

QuoteAdjustment.propTypes = {
  details: PropTypes.object,
};

export default QuoteAdjustment;