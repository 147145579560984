import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
// import DehazeIcon from '@material-ui/icons/Dehaze';
import Image from 'react-bootstrap/Image';
import Logo from '../../../assets/images/logo.png';
import { AuthenticateService } from '../../../services';
import './style.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    const isLoggedIn = AuthenticateService.verifyAuth();
    this.state = {
      isLoggedIn,
    };
  }

  onClickBackHome() {
    return window.location.href = '/';
  }

  render() {
    const currentPath = window.location.pathname;
    const isHomePage = (currentPath === '/') ? true : false;
    const isAdminPage = (currentPath === '/admin') ? true : false;
    const { isLoggedIn } = this.state;
    return (
      <Fragment>
        <Container fluid className="header-wrapper">
          <Row>
            <Col className="header-logo" lg={2} md={2} xs={8}>
              {/* <DehazeIcon className="sidebar icon"/> */}
              <div className="logo" onClick={this.onClickBackHome}>
                <Image src={Logo} />
              </div>
            </Col>
            <Col className="header-content d-none d-sm-block" lg={8} md={8} xs={false}>
              { !isHomePage && !isAdminPage ? 
                <h6>
                  Free Realtime Rate Quotes in Seconds, No SSN required <Link to="/" className="get-it-now-button">Get It Now</Link>
                </h6>
                : null }
            </Col>
            <Col className="text-right" lg={2} md={2} xs={4}>
              <Link className="btn btn-action btn-login" to="/login" role="button">{!isLoggedIn ? 'Login' : 'Logout'}</Link>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Header;
