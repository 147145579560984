import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

class AmountInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: parseFloat(this.props.value)
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const value = parseFloat(this.props.value);
      this.setState({ value });
    }
  }

  handleChange(values) {
    const { formattedValue, value } = values;
    this.setState({ value: formattedValue });
    if (this.props.onChange) {
      this.props.onChange(parseFloat(value), 'amount', this.props.index);
    }
  }

  renderAmountInput() {
    let result;
    result = (
      <Form.Group controlId={`amount-${Date.now()}`}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="amount">$</InputGroup.Text>
          </InputGroup.Prepend>
          <NumberFormat 
            customInput={FormControl} 
            value={this.state.value} 
            thousandSeparator={true} 
            onValueChange={this.handleChange} 
          />
        </InputGroup>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderAmountInput();
  }
}

AmountInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
};

export default AmountInput;

