export const CalculatorService = {
  calculatePayments,
  calculateRemainingMonths,
  calculateRemainingInt,
  calculateAdditional,
  calculatePurchasePower,
  calculateQualifyFirstHome,
  calculateAPR,
  cosignerOrNot
};

function calculateAPR(rate, loanAmount, loanTerm, closingCost, monthlyPayment) {
  if (loanAmount === 0 || rate === 0 || loanTerm === 0) {
    return 0;
  }
  const ratio = (loanAmount - closingCost) / monthlyPayment;
  const months = loanTerm * 12;
  let xOld = 0;
  let x = 1.0 / (1 + rate);
  const maxLoop = 50;
  const accuracy = 5000;
  const count = 0;
  for (;Math.floor(xOld * accuracy) !== Math.floor(x * accuracy) && count < maxLoop;) {
    xOld = x;
    x = xOld - ((xOld ** (months + 1)) - xOld * (ratio + 1) + ratio) / ((months + 1) * (xOld ** months) - (ratio + 1));
  }
  return parseFloat(((1.0 / x - 1) * 12 * 100).toFixed(3));
}

function calculateRemainingMonths(remainingBalance, monthlyPayment, rate) {
  const monthlyRatePct = rate / 1200;
  const ic = monthlyPayment / (monthlyPayment - remainingBalance * monthlyRatePct);
  const remainingMonths = Math.log(ic) / Math.log(1 + monthlyRatePct);
  return remainingMonths;
}

function calculateRemainingInt(remainingBalance, remainingMonths, monthlyPayment) {
  return remainingMonths * monthlyPayment - remainingBalance;
}

function calculatePayments(initial, years, rate, monthlyOverpayment, overpayments = []) {
  let monthlyPayment = 0;
  let balance = initial;
  let baseline = initial;
  let payments = [{ overpayment: 0, balance, baseline }];
  let partial;
  const monthlyRatePct = rate / 1200;
  monthlyPayment =
    monthlyRatePct === 0
      ? initial / years / 12
      : (initial * monthlyRatePct) /
      (1 - Math.pow(1 / (1 + monthlyRatePct), years * 12));
  for (let year = 0; year < years; year++) {
    let interestYearly = 0;
    let overpaymentYearly = 0;
    for (let month = 1; month <= 12; month++) {
      const overpayment = overpayments
        .filter(x => +x.year === year && +x.month === month)
        .reduce((acc, val) => acc + +val.amount, 0);
      let interestMonth = balance * monthlyRatePct;
      interestYearly += interestMonth;
      overpaymentYearly += overpayment;
      balance -=
        monthlyPayment + monthlyOverpayment + overpayment - interestMonth;
      baseline -= monthlyPayment - baseline * monthlyRatePct;
      if (balance <= 0) {
        balance = 0;
        if (partial === undefined && month !== 12) {
          partial = month;
        }
      }
    }
    payments.push({
      baseline,
      interestYearly,
      balance,
      partial,
      overpayment: overpaymentYearly + +monthlyOverpayment * (partial || 12)
    });
    if (partial) {partial = 0;}
  }
  return { monthlyPayment, payments };
}

function calculateAdditional(monthlyPayment, yearTerm, loanAmount, oriPayment, intRate, remainingInt) {
  // Calculate additional pay so that new payment is original payment
  let adjMonthlyPayment = monthlyPayment;
  let remainingYears = yearTerm;
  let additional = { extraMonthlyPay: 0, reducedYearTerm: 0, newTotalInterest: 0, intSaving: 0 };
  if (oriPayment > monthlyPayment) {
    additional.extraMonthlyPay = Math.round(oriPayment - monthlyPayment);
    adjMonthlyPayment = oriPayment;
    remainingYears = Math.round(calculateRemainingMonths(loanAmount, oriPayment, intRate) * 10 / 12) / 10;
    additional.reducedYearTerm = remainingYears;
  }
  // Total New interest
  const newTotalInterest = Math.round(calculateRemainingInt(loanAmount, remainingYears * 12, adjMonthlyPayment));
  additional.newTotalInterest = newTotalInterest;
  // You Save
  const intSaving = Math.round(remainingInt - newTotalInterest);
  additional.intSaving = intSaving;
  return additional;
}

function calculatePurchasePower(monthlyIncome, monthlyDebts, downPayment, rate, newRentalIncome, dti = 0.43, monthTerm = 360, yearlyRatio = 0.013) {
  const monthlyTIR = yearlyRatio / 12;
  const monthlyInt = rate / 100 / 12;
  let { loanAmount, cstN } = this.calculateQualifyFirstHome(monthlyIncome, monthlyDebts, downPayment, dti, monthTerm, monthlyTIR, monthlyInt, newRentalIncome);
  const purchasePower = downPayment + loanAmount;
  const monthlyPayment = Math.round(loanAmount * cstN);
  const taxInsurance = Math.round((loanAmount + downPayment) * monthlyTIR);
  return {
    loanAmount,
    purchasePower,
    monthlyPayment,
    taxInsurance,
  };
}

function calculateQualifyFirstHome(monthlyIncome, monthlyDebts, downPayment, dti, monthTerm, monthlyTIR, monthlyInt, newRentalIncome) {
  const cstL = monthlyIncome * dti - monthlyDebts + newRentalIncome;
  const interestCnst = Math.pow(1 + monthlyInt, monthTerm);
  const cstN = (monthlyInt * interestCnst) / (interestCnst - 1);
  const loanAmount = Math.round((cstL - (downPayment * monthlyTIR)) / (cstN + monthlyTIR));
  return { loanAmount, cstN };
}


function cosignerOrNot() {
  let { baseIncome, haveConsistentOvertime, addtionalIncome, haveOtherJob, otherJobs,
    studentLoans, carLoans, creditCardDue,
    otherDebts, loanAmount, rate,
    propertyTax, monthlyHoa, monthlyExpenses,
    haveInvestmentProperty, investmentRentValue, otherProperties
  } = this.state;
  let allPropertyIncomes = 0;
  let allPropertyDebts = 0;
  let otherJobIncomes = 0;
  const dti = +loanAmount > 765000 ? 0.43 : 0.5;
  if(!haveConsistentOvertime) {
    addtionalIncome = 0;
  }
  if(haveOtherJob) {
    otherJobIncomes = otherJobs.reduce((total, value ) => total = total + value.income, 0);
  } else {
    otherJobIncomes = 0;
  }
  const personalIncome = +baseIncome + +addtionalIncome + +otherJobIncomes;
  const personalDebt = +studentLoans + +carLoans + +creditCardDue + +otherDebts;
  const monthlyInt = +rate / 100 / 12;
  const monthTerm = 360;
  const interestCnst = Math.pow(1 + monthlyInt, monthTerm);
  const cstN =  (monthlyInt * interestCnst) / (interestCnst - 1);
  const monthlyPayment = Math.round(+loanAmount * cstN) || 0;
  const subjectPropertyExpense = monthlyPayment + +propertyTax + +monthlyHoa + +monthlyExpenses;
  let rentValue = 0;
  if(haveInvestmentProperty) {
    rentValue = (investmentRentValue * 75 / 100) - subjectPropertyExpense;
  } else {
    allPropertyDebts = allPropertyDebts + subjectPropertyExpense;
  }
  (rentValue < 0) ? allPropertyDebts = allPropertyDebts + Math.abs(rentValue): allPropertyIncomes = rentValue;
  const otherPropertyValues = otherProperties.reduce((propertyValues, property) => {
    (property.approximateNet < 0) ? propertyValues.debts = Math.abs(+property.approximateNet) : propertyValues.income = +property.approximateNet;
    return propertyValues;
  }, { debts: 0, income: 0});
  allPropertyIncomes = allPropertyIncomes + otherPropertyValues.income;
  allPropertyDebts = allPropertyDebts + otherPropertyValues.debts;
  const allIncome = personalIncome + allPropertyIncomes;
  const allDebt = personalDebt + allPropertyDebts;
  const totalIncomeNeedToQualify = allDebt * (1 / dti) - allPropertyIncomes;
  const reasoning = (totalIncomeNeedToQualify < allIncome) ? 'No need to add cosign' : 'Add cosign';
  return {
    dti,
    personalIncome,
    personalDebt,
    allPropertyIncomes,
    allPropertyDebts,
    allIncome,
    allDebt,
    totalIncomeNeedToQualify,
    reasoning,
  };
}