import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import './Admin.scss';
import Header from './../Header';
import AdminSidebar from './../../Sidebar/Admin';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    fontSize: 8.75,
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: '#35d7a9',
      dark: '#1eba8e',
    },
  },
  overrides: {
    MuiTable: {
      root: {
        marginTop: 10
      }
    },
    MuiTableCell: {
      root: {
        fontSize: 13,
      }
    },
    MuiTablePagination: {
      root: {
        fontSize: 13,
      }
    },
    MuiChip: {
      root: {
        borderRadius: 3,
        marginRight: 5,
        marginLeft: 5
      }
    },
    MuiDialogContent: {
      root: {
        padding: 10,
        '&:first-child': {
          paddingTop: 10,
        },
      }
    },
    MuiDialogActions: {
      root: {
        padding: '0 10px 10px',
        justifyContent: 'flex-start'
      }
    },
    MuiCardHeader: {
      root: {
        padding: '10px 16px',
      }
    }
  }
});
class AdminLayout extends React.Component {
  render() {
    return (
      <div className="admin-layout">
        <Header />
        <Grid container spacing={0} className="admin-container">
          <Grid item md={2}>
            <AdminSidebar />
          </Grid>
          <Grid item md={10} >
            <div className="main-wrapper">
              <MuiThemeProvider theme={theme}>
                {this.props.children}
              </MuiThemeProvider>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

AdminLayout.propTypes = {
  children: PropTypes.any, 
};

export default AdminLayout;
