import React from 'react';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';

import ListItemLink from '../ListItemLink';

function AdminSidebar() {
  return (
    <div className="sidebar-wrapper">
      <List disablePadding dense>
        <ListItemLink to={'/admin/'}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </ListItemLink>
      </List>
    </div>
  );
}
  
export default AdminSidebar;