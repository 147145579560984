import { ApiService } from './api.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();

export const SettingService = {
  getLowestRateLenders,
  getRateOptimizeListing,
  updateLowestRateLenders,
  updateRateOptimize,
};

const requestOptions = {
  headers: authHeader()
};

function getLowestRateLenders() {
  return apiService.get('v1/settings/?type=lowestrate', requestOptions);
}

function updateLowestRateLenders(lenders) {
  return apiService.post('v1/settings/?type=lowestrate', lenders, requestOptions);
}

function getRateOptimizeListing() {
  return apiService.get('v1/settings/rateOptimization', requestOptions);
}

function updateRateOptimize(id, rateOptimize) {
  return apiService.post(`v1/settings/rateOptimization/${id}`, rateOptimize, requestOptions);
}