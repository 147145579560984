import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import LoanAmountInput from '../Controls/InputLoanAmount';
import PropertyValueInput from '../Controls/InputPropertyValue';
import ZipcodeInput from '../Controls/InputZipcode';
import LTVInput from '../Controls/InputLTV';
import LoanTermSelectBox from '../Controls/SelectBoxLoanTerm';

class BasicAssumptions extends Component {

  constructor(props) {
    super(props);
    const basicAssumptions = this.props.basicAssumptions;
    this.state = {
      loanPurpose: basicAssumptions.loanPurpose,
      loanAmount: basicAssumptions.loanAmount,
      propertyValue: basicAssumptions.propertyValue,
      loanTerm: basicAssumptions.loanTerm,
      zipCode: basicAssumptions.zipCode,
      cashOut: basicAssumptions.cashOut,
    };
    this.handleAssumptionsChange = this.handleAssumptionsChange.bind(this);
    this.handleLoanPurposeChange = this.handleLoanPurposeChange.bind(this);
    this.handleCashoutChange = this.handleCashoutChange.bind(this);
  }

  handleAssumptionsChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }), () => this.props.onBasicAssumptionsFetched(this.state));
  }

  handleLoanPurposeChange(loanPurpose, cashOut) {
    this.setState(prevState => ({
      ...prevState,
      loanPurpose,
      cashOut
    }), () => this.props.onBasicAssumptionsFetched(this.state));
  }

  handleCashoutChange() {
    this.setState(prevState => ({
      ...prevState,
      cashOut: !prevState.cashOut,
    }), () => this.props.onBasicAssumptionsFetched(this.state));
  }

  render() {
    return (
      <Container fluid className="basic-assumptions">
        <Row className="justify-content-md-center">
          <Col xs={4} md={3} lg={2} className="text-center">
            <div 
              className={`btn-loan-purpose ${this.state.loanPurpose === 'Purchase' ? 'selected': null}`} 
              onClick={() => this.handleLoanPurposeChange('Purchase', false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg"  width="450" height="450" viewBox="0 0 640 512">
                <path fill="currentColor" d="M320 384H128V224H64v256c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V224h-64v160zm314.6-241.8l-85.3-128c-6-8.9-16-14.2-26.7-14.2H117.4c-10.7 0-20.7 5.3-26.6 14.2l-85.3 128c-14.2 21.3 1 49.8 26.6 49.8H608c25.5 0 40.7-28.5 26.6-49.8zM512 496c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V224h-64v272z"></path>
              </svg>
              <h6>Purchase</h6>
            </div>
          </Col>
          <Col xs={4} md={3} lg={2} className="text-center">
            <div 
              className={`btn-loan-purpose ${this.state.loanPurpose === 'Refinance' && !this.state.cashOut ? 'selected': null}`} 
              onClick={() => this.handleLoanPurposeChange('Refinance', false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="450" height="450" viewBox="0 0 576 512">
                <path fill="currentColor" d="M560 224h-29.5c-8.8-20-21.6-37.7-37.4-52.5L512 96h-32c-29.4 0-55.4 13.5-73 34.3-7.6-1.1-15.1-2.3-23-2.3H256c-77.4 0-141.9 55-156.8 128H56c-14.8 0-26.5-13.5-23.5-28.8C34.7 215.8 45.4 208 57 208h1c3.3 0 6-2.7 6-6v-20c0-3.3-2.7-6-6-6-28.5 0-53.9 20.4-57.5 48.6C-3.9 258.8 22.7 288 56 288h40c0 52.2 25.4 98.1 64 127.3V496c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-48h128v48c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-80.7c11.8-8.9 22.3-19.4 31.3-31.3H560c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zm-128 64c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zM256 96h128c5.4 0 10.7.4 15.9.8 0-.3.1-.5.1-.8 0-53-43-96-96-96s-96 43-96 96c0 2.1.5 4.1.6 6.2 15.2-3.9 31-6.2 47.4-6.2z"></path>
              </svg>
              <h6>Refinance</h6>
            </div>
          </Col>
          <Col xs={4} md={3} lg={2} className="text-center">
            <div 
              className={`btn-loan-purpose ${this.state.loanPurpose === 'Refinance' && this.state.cashOut ? 'selected': null}`} 
              onClick={() => this.handleLoanPurposeChange('Refinance', true)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="450" height="450" viewBox="0 0 640 512">
                <path fill="currentColor" d="M320 144c-53.02 0-96 50.14-96 112 0 61.85 42.98 112 96 112 53 0 96-50.13 96-112 0-61.86-42.98-112-96-112zm40 168c0 4.42-3.58 8-8 8h-64c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h16v-55.44l-.47.31a7.992 7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09l15.33-10.22a23.99 23.99 0 0 1 13.31-4.03H328c4.42 0 8 3.58 8 8v88h16c4.42 0 8 3.58 8 8v16zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zm-16 272c-35.35 0-64 28.65-64 64H112c0-35.35-28.65-64-64-64V176c35.35 0 64-28.65 64-64h416c0 35.35 28.65 64 64 64v160z"></path>
              </svg>
              <h6>Cashout</h6>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={12} md={4} lg={3}>
            <Row className="loan-amount-ltv">
              <Col xs={8} md={8} lg={8}>
                <LoanAmountInput label="Loan Amount" value={this.state.loanAmount} onChange={this.handleAssumptionsChange}/>
              </Col>
              <Col xs={4} md={4} lg={4} className="ltv">
                <LTVInput label="LTV" loanAmount={this.state.loanAmount} propertyValue={this.state.propertyValue} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Row className="property-value-zipcode">
              <Col xs={8} md={8} lg={8} className="property-value">
                <PropertyValueInput label="Property Value" value={this.state.propertyValue} onChange={this.handleAssumptionsChange} />
              </Col>
              <Col xs={4} md={4} lg={4} className="zipcode">
                <ZipcodeInput label="Zip Code" value={this.state.zipCode} onChange={this.handleAssumptionsChange} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <LoanTermSelectBox label="Loan Term" value={this.state.loanTerm} onChange={this.handleAssumptionsChange} />
          </Col>
        </Row>
      </Container>
    );
  }
}

BasicAssumptions.propTypes = {
  basicAssumptions: PropTypes.object,
  onBasicAssumptionsFetched: PropTypes.func,
};

export default BasicAssumptions;