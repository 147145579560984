import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RateComparison from '../../components/LoanAssistant/RateComparison/RateComparison';
import { Modal } from 'react-bootstrap';

class RateComparisonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.isShow || false,
      rateAssumptions: [],
    };
    this.onClickCloseModal = this.onClickCloseModal.bind(this);
  }

  componentDidMount() {
    const { rateComparison } = this.props;
    this.handleRateAssumptions(rateComparison);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.showModal !== this.props.isShow) {
      this.setState({
        showModal: this.props.isShow,
      });
    }
  }

  handleRateAssumptions(rates) {
    if (rates.length) {
      const rateAssumptions = rates.map(x => {
        const { quote, loanTerm } = x;
        return {
          loanAmount: quote.loanAmount,
          year: loanTerm,
          rate: quote.rate,
        };
      });
      this.setState(prevState => ({
        ...prevState,
        rateAssumptions
      }));
    }
  }

  onClickCloseModal() {
    this.setState({
      showModal: false,
    });
    this.props.hiddenModal(true);
  }

  render() {
    const { showModal, rateAssumptions } = this.state;
    return (
      <Fragment>
        <Modal
          size="xl"
          show={showModal}
          onHide={this.onClickCloseModal}
        >
          <Modal.Body>
            <RateComparison assumptions={rateAssumptions} />
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

RateComparisonModal.propTypes = {
  isShow: PropTypes.bool,
  hiddenModal: PropTypes.func,
  rateComparison: PropTypes.array,
};

export default RateComparisonModal;
