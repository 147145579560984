import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

class PropertyTypeSelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: (this.props.value) || '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const value = event.target.value;
    const fieldName = event.target.name;
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value, fieldName);
    }
  }

  renderPropertyTypeSelectBox = () => {
    return (
      <Form.Group controlId="property-type">
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <Form.Control 
          size="sm" 
          as="select"
          name="propertyType"
          value={this.state.value}
          onChange={this.handleChange}
        >
          <option value={'SingleFamily'}>Single Family</option>
          <option value={'Condo'}>Condo</option>
          <option value={'Townhouse'}>Townhouse</option>
          <option value={'Duplex'}>Duplex</option>
          <option value={'Triplex'}>Triplex</option>
          <option value={'Fourplex'}>Fourplex</option>
        </Form.Control>
      </Form.Group>
    );
  }

  render() {
    return this.renderPropertyTypeSelectBox();
  }
}

PropertyTypeSelectBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default PropertyTypeSelectBox;

