import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import DoneIcon from '@material-ui/icons/Done';
import QuoteTable from '../../components/Quote/Result';
import BasicAssumptions from '../../components/Assumptions/Basic';
import AdvancedAssumptions from '../../components/Assumptions/Advanced';
import './style.scss';

import { getQuote, getLoanTypes } from '../../actions';

class RealTimeRate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      journey: 'stage-1',
      assumptions: {
        basic: {
          loanPurpose: 'Refinance',
          loanAmount: 500000,
          propertyValue: 1000000,
          loanTerm: 'Fixed_30',
          zipCode: '95111',
          cashOut: false,
        },
        advanced: {
          loanType: '',
          creditScore: 760,
          propertyType: 'SingleFamily',
          propertyUsage: 'Primary',
          lockDays: 30,
        }
      }
    };
    this.onClickGetQuote = this.onClickGetQuote.bind(this);
    this.handleBasicAssumptions = this.handleBasicAssumptions.bind(this);
    this.handleAdvancedAssumptions = this.handleAdvancedAssumptions.bind(this);
  }

  componentDidMount() {
    this.props.getLoanTypes();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.quote !== this.props.quote && (this.props.quote && this.props.quote.length)) {
      this.props.nextStage(true);
      this.setState(prevState => ({
        ...prevState,
        journey: 'stage-2',
        submitted: true,
      }), () => this.forceUpdate());
    }
    if(prevProps.loanTypes !== this.props.loanTypes) {
      const loanTypes = (this.props.loanTypes.length) ? this.props.loanTypes : [];
      if(loanTypes.length) {
        const conventionalIndex = loanTypes.findIndex(loantype => loantype.name === 'Conventional');
        if(conventionalIndex >= 0) {
          const loanType = loanTypes[conventionalIndex]._id;
          this.setState(prevState => ({
            ...prevState,
            assumptions: {
              ...prevState.assumptions,
              advanced: {...prevState.assumptions.advanced, loanType}
            }
          }));
        }
      }
    }
  }

  onClickGetQuote() {
    const { loanAmount, propertyValue } = this.state.assumptions.basic;
    if (loanAmount && propertyValue) {
      const assumptions = {...this.state.assumptions.basic, ...this.state.assumptions.advanced};
      this.props.getQuote(assumptions);
    }
  }

  handleBasicAssumptions(basicAssumptions) {
    this.setState(prevState => ({
      ...prevState,
      submitted: false,
      assumptions: {
        ...prevState.assumptions,
        basic: {...prevState.assumptions.basic, ...basicAssumptions}
      }
    }));
  }

  handleAdvancedAssumptions(advancedAssumptions) {
    this.setState(prevState => ({
      ...prevState,
      submitted: false,
      assumptions: {
        ...prevState.assumptions,
        advanced: {...prevState.assumptions.advanced, ...advancedAssumptions}
      }
    }));
  }

  render() {
    return (
      <Fragment>
        <section className="realtime-rate-assumptions">
          <Container>
            <Row>
              <Col className="text-center">
                <h3 className="title">Realtime Rate</h3>
                <p className="sub-title">(in seconds, no SSN required)</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <BasicAssumptions 
                  basicAssumptions={this.state.assumptions.basic} 
                  onBasicAssumptionsFetched={this.handleBasicAssumptions} 
                />
                { this.state.journey === 'stage-2' ? 
                  <AdvancedAssumptions 
                    advancedAssumptions={this.state.assumptions.advanced} 
                    onAdvancedAssumptionsFetched={this.handleAdvancedAssumptions} 
                  /> : null }
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button 
                  variant="primary" 
                  className='btn-action btn-get-quote'
                  disabled={this.state.submitted}
                  onClick={this.onClickGetQuote}
                >
                  <DoneIcon /> SHOP LOWEST RATE NOW
                </Button>
              </Col>
            </Row>
          </Container>
        </section>
        { this.state.journey === 'stage-2' ? <section>
          <Container className="realtime-rate-results">
            <Row><Col><QuoteTable quote={this.props.quote} assumptions={{...this.state.assumptions.basic, ...this.state.assumptions.advanced}} /></Col></Row>
          </Container>
        </section> : null }
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    quote: state.quote.quote,
    loanTypes: state.loanType.loanTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuote: (assumptions) => dispatch(getQuote(assumptions)),
    getLoanTypes: () => dispatch(getLoanTypes())
  };
};

RealTimeRate.propTypes = {
  quote: PropTypes.array,
  loanTypes: PropTypes.array,
  nextStage: PropTypes.func,
  getQuote: PropTypes.func,
  getQuoteOptimize: PropTypes.func,
  getLoanTypes: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RealTimeRate));
