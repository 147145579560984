import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

class PropertyValueInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: parseFloat(this.props.value)
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(values) {
    const { formattedValue, value } = values;
    this.setState({ value: formattedValue });
    if (this.props.onChange) {
      this.props.onChange(parseFloat(value), 'propertyValue');
    }
  }

  renderPropertyValueInput() {
    let result;
    result = (
      <Form.Group controlId={`property-value-${Date.now()}`}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="property-value">$</InputGroup.Text>
          </InputGroup.Prepend>
          <NumberFormat 
            customInput={FormControl} 
            value={this.state.value} 
            thousandSeparator={true} 
            onValueChange={this.handleChange} 
          />
        </InputGroup>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderPropertyValueInput();
  }
}

PropertyValueInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default PropertyValueInput;

