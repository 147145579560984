import {
  GET_MORTGAGE_CALCULATE,
  COMPARISON_RATE,
  REMAINING_MONTHS,
  PURCHASE_POWER,
} from './types';
import { CalculatorService } from '../services';
    
export const mortgageCalculator = (initial, years, rate, monthlyOverpayment, overpayments = [], fees = 0) => {
  return (dispatch) => {
    const mortgageResult = CalculatorService.calculatePayments(
      parseFloat(initial || 0), 
      parseFloat(years || 0),
      parseFloat(rate || 0), 
      parseFloat(monthlyOverpayment || 0), 
      overpayments
    );
    const apr = CalculatorService.calculateAPR(
      parseFloat(rate || 0), 
      parseFloat(initial || 0), 
      parseFloat(years || 0),
      parseFloat(fees || 0), 
      parseFloat(mortgageResult.monthlyPayment || 0), 
    );
    dispatch({
      type: GET_MORTGAGE_CALCULATE,
      mortgageResult: mortgageResult,
      apr: apr,
    });
  };
};

export const comparisonRate = (initial, years, rate, monthlyOverpayment, index) => {
  return (dispatch) => {
    const mortgageResult = CalculatorService.calculatePayments(
      parseFloat(initial || 0), 
      parseFloat(years || 0),
      parseFloat(rate || 0), 
      parseFloat(monthlyOverpayment || 0), 
    );
    dispatch({
      type: COMPARISON_RATE,
      data: mortgageResult,
      index: index,
    });
  };
};

export const calculateRemaining = (remainingBalance, monthlyPayment, rate, newTerm, newRate, newLoanAmount) => {
  return (dispatch) => {
    const remainingMonths = CalculatorService.calculateRemainingMonths(
      parseFloat(remainingBalance || 0), 
      parseFloat(monthlyPayment || 0),
      parseFloat(rate || 0)
    );
    const remainingInt = CalculatorService.calculateRemainingInt(
      parseFloat(remainingBalance || 0),
      parseFloat(remainingMonths || 0),
      parseFloat(monthlyPayment || 0)
    );
    const newMonthlyPayment  = CalculatorService.calculatePayments(
      parseFloat(newLoanAmount || 0),
      parseFloat(newTerm || 0),
      parseFloat(newRate || 0),
      0
    ).monthlyPayment;
    const additional  = CalculatorService.calculateAdditional(
      parseFloat(newMonthlyPayment || 0), 
      parseFloat(newTerm || 0),
      parseFloat(newLoanAmount || 0), 
      parseFloat(monthlyPayment || 0), 
      parseFloat(newRate || 0),
      parseFloat(remainingInt || 0), 
    );
    dispatch({
      type: REMAINING_MONTHS,
      remainingMonths: remainingMonths,
      remainingInt: remainingInt,
      newMonthlyPayment: newMonthlyPayment,
      additional: additional,
    });
  };
};

export const calculatePurchasePower = (monthlyIncome, monthlyDebts, downPayment, rate, newRentalIncome) => {
  return (dispatch) => {
    const easyQualified = CalculatorService.calculatePurchasePower(
      parseFloat(monthlyIncome || 0), 
      parseFloat(monthlyDebts || 0),  
      parseFloat(downPayment || 0), 
      parseFloat(rate|| 0), 
      parseFloat(newRentalIncome|| 0), 
      0.43,
    );
    const maxQualified = CalculatorService.calculatePurchasePower(
      parseFloat(monthlyIncome || 0), 
      parseFloat(monthlyDebts || 0),  
      parseFloat(downPayment || 0), 
      parseFloat(rate|| 0), 
      parseFloat(newRentalIncome|| 0), 
      0.5,
    );
    dispatch({
      type: PURCHASE_POWER,
      data: { easyQualified, maxQualified },
    });
  };
};
