import React, { Component, Fragment } from 'react';
import SiteBreadcrumb from '../../components/Breadcrumb';
import CosignerOrNot from '../../components/LoanAssistant/CosignerOrNot/CosignerOrNot';

class CosignerOrNotContainer extends Component {

  render() {
    const crumbs = [
      { path: '/', name: 'Home'},
      { path: '/', name: 'Cosign Or Not'},
    ];
    return (
      <Fragment>
        <SiteBreadcrumb crumbs={crumbs}/>
        <CosignerOrNot />
      </Fragment>
    );
  }
}

export default CosignerOrNotContainer;
