import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LoanAmountInput from '../../Controls/InputLoanAmount';
import CurrencyInput from '../../Controls/InputCurrency';
import YearInput from '../../Controls/InputYear';
import RateInput from '../../Controls/InputRate';
import MortgageCalculatorResult from './MortgageCalculatorResult';
import './style.scss';

import { mortgageCalculator } from '../../../actions';
import { percentFormat } from '../../../utils';

class MortgageCalculator extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: 400000,
      year: 30,
      rate: 3,
      fees: 0,
      getAPR: false,
    };
    this.handleAssumptionsChange = this.handleAssumptionsChange.bind(this);
    this.mortgageCalculator = this.mortgageCalculator.bind(this);
    this.handleClickAPR = this.handleClickAPR.bind(this);
  }

  componentDidMount() {
    this.mortgageCalculator();
  }

  handleAssumptionsChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }), () => this.mortgageCalculator());
  }
  
  mortgageCalculator() {
    const { loanAmount, year, rate, fees } = this.state;
    this.props.mortgageCalculator(loanAmount, year, rate, 0, [], fees);
  }

  handleClickAPR() {
    this.setState(prevState => ({
      ...prevState,
      getAPR: !prevState.getAPR,
    }));
  }

  render() {
    return (
      <section className="mortgage-calculator-wrapper">
        <Container>
          <Row >
            <Col xs={12} md={12} lg={12}>
              <h5 className="page-title">Mortgage Calculator</h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={2} lg={1}>
              <label>Amount</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <LoanAmountInput label="" value={this.state.loanAmount} onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={2} lg={1}>
              <label>Years</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <YearInput label="" value={this.state.year} onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={2} lg={1}>
              <label>Rate</label>
            </Col>
            <Col xs={12} md={5} lg={4}>
              <RateInput label="" value={this.state.rate} onChange={this.handleAssumptionsChange} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={5}>
              <h6 className="expand-title" onClick={this.handleClickAPR}>View APR {this.state.getAPR ? <RemoveIcon className="collapse-icon"/> : <AddIcon className="expand-icon"/>}</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={7} lg={5}>
              {this.state.getAPR ? <div className="indent-box">
                <Row className="align-items-center">
                  <Col xs={4} md={4} lg={4}>
                    <label>Closing Cost</label>
                  </Col>
                  <Col xs={8} md={6} lg={8}>
                    <CurrencyInput value={this.state.fees} name="fees" id="fees" onChange={this.handleAssumptionsChange} />
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={4} md={4} lg={4}>
                    <label>Final APR</label>
                  </Col>
                  <Col xs={8} md={8} lg={8}>
                    <span className="badge badge-green">{percentFormat(this.props.apr, 2)}</span>
                  </Col>
                </Row>
              </div> : null }
            </Col>
          </Row>
        </Container>
        {this.props.mortgageResult && Object.keys(this.props.mortgageResult).length > 0 
          ? <MortgageCalculatorResult mortgageResult={this.props.mortgageResult} /> 
          : null}
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {
    mortgageResult: state.calculator.mortgageResult,
    apr: state.calculator.apr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mortgageCalculator: (loanAmount, years, rate, monthlyOverpayment, overpayments, fees) => dispatch(mortgageCalculator(loanAmount, years, rate, monthlyOverpayment, overpayments, fees)),
  };
};

MortgageCalculator.propTypes = {
  mortgageCalculator: PropTypes.func,
  mortgageResult: PropTypes.object,
  apr: PropTypes.number,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MortgageCalculator));