import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-bootstrap';

import './style.scss';

class SiteBreadcrumb extends Component {
  render() {
    const { crumbs } = this.props;
    if (crumbs.length <= 1) {
      return null;
    }
    return (
      <div className="breadcrumb-wrapper">
        <div className="container">
          <Breadcrumb>
            {crumbs.map(({ name, path }, key) =>
              key + 1 === crumbs.length ? (
                <Breadcrumb.Item active key={key}>{name}</Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item href={path} key={key}>{name}</Breadcrumb.Item>
              )
            )}
          </Breadcrumb>
        </div>
      </div>
    );
  }
}

SiteBreadcrumb.propTypes = {
  crumbs: PropTypes.array,
};

export default SiteBreadcrumb;
