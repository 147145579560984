import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CurrencyInput from '../../Controls/InputCurrency';
import LoanAmountInput from '../../Controls/InputLoanAmount';
import RateInput from '../../Controls/InputRate';
import MonthlyPaymentInput from '../../Controls/InputMonthlyPayment';
import MonthlyIncomeNameInput from '../../Controls/InputMonthlyIncomeName';
import MonthlyIncomeInput from '../../Controls/InputMonthlyIncome';
import { currencyFormat } from '../../../utils';
import './style.scss';

class CosignerOrNot extends Component {
  constructor(props) {
    super(props);
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const mode = params.get('mode');

    this.state = {
      expandCollapse: {
        personalIncome: true,
        personalDebts: false,
        subjectProperty: false,
        secondProperty: false,
        existingProperty: false,
      },
      baseIncome: 10000,
      haveConsistentOvertime: false,
      addtionalIncome: 0,
      haveOtherJob: false,
      otherJobs: [
        { name: '', income: 0 },
      ],
      studentLoans: 0,
      carLoans: 0,
      creditCardDue: 0,
      otherDebts: 0,
      loanAmount: 500000,
      rate: 3,
      monthlyPayment: 0,
      propertyTax: 600,
      monthlyHoa: 0,
      monthlyInsurance: 0,
      monthlyExpenses: 0,
      haveInvestmentProperty: false,
      investmentRentValue: 0,
      secondProperties: [
        { 
          mortgage: 0,
          tax: 0,
          insurance: 0,
          hoa: 0,
        }
      ],
      existingProperties: [
        { 
          rent: 0,
          mortgage: 0,
          tax: 0,
          insurance: 0,
          hoa: 0,
          filedOnTax: 0,
        }
      ],  
      result: {
        dti: 0,
        personalIncome: 0,
        personalDebt: 0,
        allPropertyIncomes: 0,
        allPropertyDebts: 0,
        subjectProperty: 0,
        secondProperty: 0,
        existingProperty: 0,
        allIncome: 0,
        allDebt: 0,
        totalIncomeNeedToQualify: 0,
        reasoning: '',
      },
      mode
    };
    this.handleClickConsistentOvertime = this.handleClickConsistentOvertime.bind(this);
    this.handleClickOtherJob = this.handleClickOtherJob.bind(this);
    this.handleClickInvestmentProperty = this.handleClickInvestmentProperty.bind(this);
    this.handleAssumptionsChange = this.handleAssumptionsChange.bind(this);
    this.handleAddOtherJob = this.handleAddOtherJob.bind(this);
    this.handleDeleteOtherJob = this.handleDeleteOtherJob.bind(this);
    this.handleOtherJobChange = this.handleOtherJobChange.bind(this);
    this.handleClickExpandCollapse = this.handleClickExpandCollapse.bind(this);

    this.handleAddSecondProperty = this.handleAddSecondProperty.bind(this);
    this.handleDeleteSecondProperty = this.handleDeleteSecondProperty.bind(this);
    this.handleSecondPropertyChange = this.handleSecondPropertyChange.bind(this);

    this.handleAddExistingProperty = this.handleAddExistingProperty.bind(this);
    this.handleDeleteExistingProperty = this.handleDeleteExistingProperty.bind(this);
    this.handleExistingPropertyChange = this.handleExistingPropertyChange.bind(this);
  }

  componentDidMount() {
    this.mortgageCalculator();
  }

  // handle second property section
  handleAddSecondProperty() {
    this.setState(prevState => ({
      ...prevState,
      secondProperties: [
        ...prevState.secondProperties,
        { 
          mortgage: 0,
          tax: 0,
          insurance: 0,
          hoa: 0,
        },
      ]
    }));
  }

  handleDeleteSecondProperty(index) {
    const secondProperties = [...this.state.secondProperties];
    if(index >= 0) {
      secondProperties.splice(index, 1);
      this.setState(prevState => ({
        ...prevState,
        secondProperties
      }), () => this.mortgageCalculator());
    }
  }
  
  handleSecondPropertyChange(value, fieldName, index) {
    const { secondProperties } = this.state;
    if(index >= 0) {
      secondProperties[index] = { ...secondProperties[index], [fieldName]: value };
    }
    this.setState(prevState => ({
      ...prevState,
      secondProperties
    }), () => this.calculateSecondProperty());
  }


  calculateSecondProperty() {
    const { secondProperties } = this.state;
    const secondPropertyDebt = secondProperties.reduce((total, currentValue) => {
      const { mortgage, tax, insurance, hoa } = currentValue;
      return total += mortgage + tax + insurance + hoa;
    }, 0);
    this.setState(prevState => ({
      ...prevState,
      result: {
        ...prevState.result,
        secondProperty: secondPropertyDebt,
      }
    }), () => this.mortgageCalculator());
  }

  // handle existing property section
  handleAddExistingProperty() {
    this.setState(prevState => ({
      ...prevState,
      existingProperties: [
        ...prevState.existingProperties,
        { 
          rent: 0,
          mortgage: 0,
          tax: 0,
          insurance: 0,
          hoa: 0,
          filedOnTax: 0,
        },
      ]
    }));
  }

  handleDeleteExistingProperty(index) {
    const existingProperties = [...this.state.existingProperties];
    if(index >= 0) {
      existingProperties.splice(index, 1);
      this.setState(prevState => ({
        ...prevState,
        existingProperties
      }), () => this.mortgageCalculator());
    }
  }

  handleExistingPropertyChange(value, fieldName, index) {
    const { existingProperties } = this.state;
    if(index >= 0) {
      existingProperties[index] = { ...existingProperties[index], [fieldName]: value };
    }
    this.setState(prevState => ({
      ...prevState,
      existingProperties
    }), () => this.calculateExistingProperty());
  }

  calculateExistingProperty() {
    const { existingProperties } = this.state;   
    const netExistingProperty = existingProperties.reduce((totalNetRental, currentValue) => {
      const { rent, mortgage, tax, insurance, hoa, filedOnTax } = currentValue;
      const expenses = mortgage + tax + insurance + hoa + filedOnTax;
      const netRental = rent - expenses;
      totalNetRental += netRental;
      return totalNetRental;
    }, 0);
    this.setState(prevState => ({
      ...prevState,
      result: {
        ...prevState.result,
        existingProperty: netExistingProperty,
      }
    }), () => this.mortgageCalculator());
  }
  
  handleClickConsistentOvertime() {
    this.setState(prevState => ({
      ...prevState,
      haveConsistentOvertime: !prevState.haveConsistentOvertime
    }), () => this.mortgageCalculator());
  }

  handleClickInvestmentProperty() {
    this.setState(prevState => ({
      ...prevState,
      haveInvestmentProperty: !prevState.haveInvestmentProperty
    }), () => this.mortgageCalculator());
  }

  handleClickOtherJob() {
    this.setState(prevState => ({
      ...prevState,
      haveOtherJob: !prevState.haveOtherJob
    }), () => this.mortgageCalculator());
  }

  handleAssumptionsChange(value, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }), () => this.mortgageCalculator());
  }

  handleAddOtherJob() {
    this.setState(prevState => ({
      ...prevState,
      otherJobs: [
        ...prevState.otherJobs,
        { name: '', income: 0 },
      ]
    }));
  }

  handleDeleteOtherJob(index) {
    const otherJobs = [...this.state.otherJobs];
    if(index >= 0) {
      otherJobs.splice(index, 1);
      this.setState(prevState => ({
        ...prevState,
        otherJobs
      }), () => this.mortgageCalculator());
    }
  }

  handleOtherJobChange(value, fieldName, index) {
    const otherJobs = this.state.otherJobs;
    if(index >= 0) {
      otherJobs[index] = { ...otherJobs[index], [fieldName]: value };
    }
    this.setState(prevState => ({
      ...prevState,
      otherJobs
    }), () => this.mortgageCalculator());
  }

  handleClickExpandCollapse(name) {
    this.setState(prevState => ({
      ...prevState,
      expandCollapse: {
        ...prevState.expandCollapse,
        [name]: !prevState.expandCollapse[name],
      }
    }));
  }

  mortgageCalculator() {
    let allPropertyIncomes = 0;
    let allPropertyDebts = 0;
    let otherJobIncomes = 0;

    let { baseIncome, haveConsistentOvertime, addtionalIncome, haveOtherJob, otherJobs,
      studentLoans, carLoans, creditCardDue,
      otherDebts, loanAmount, rate,
      propertyTax, monthlyInsurance, monthlyHoa, monthlyExpenses,
      haveInvestmentProperty, investmentRentValue, result
    } = this.state;

    const { secondProperty, existingProperty } = result;

    const dti = +loanAmount > 765000 ? 0.43 : 0.5;

    // Calculate personal income
    if(!haveConsistentOvertime) {
      addtionalIncome = 0;
    }
    if(haveOtherJob) {
      otherJobIncomes = otherJobs.reduce((total, value ) => total = total + value.income, 0);
    } else {
      otherJobIncomes = 0;
    }
    const personalIncome = +(baseIncome || 0) + +(addtionalIncome || 0) + +(otherJobIncomes || 0);
    // Personal debts
    const personalDebt = +(studentLoans || 0) + +(carLoans || 0) + +(creditCardDue || 0) + +(otherDebts || 0);

    const monthlyInt = +rate / 100 / 12;
    const monthTerm = 360;
    const interestCnst = Math.pow(1 + monthlyInt, monthTerm);
    const cstN =  (monthlyInt * interestCnst) / (interestCnst - 1);
    const monthlyPayment = Math.round(+loanAmount * cstN) || 0;

    // Subject (Primary) property debts
    const subjectProperty = monthlyPayment + +(propertyTax || 0) + +(monthlyInsurance || 0) + +(monthlyHoa || 0) + +(monthlyExpenses || 0);

    // Calculate Net income of Subject (Primary) property
    let rentValue = 0;
    if(haveInvestmentProperty) {
      rentValue = (investmentRentValue * 75 / 100) - subjectProperty;
      // if rental value < 0 => debt, should add to allPropertyDebts
      (rentValue < 0) ? allPropertyDebts = allPropertyDebts + Math.abs(rentValue): allPropertyIncomes = rentValue;
    } else {
      allPropertyDebts = allPropertyDebts + subjectProperty;
    }

    // add second property debt to all property debt
    allPropertyDebts = allPropertyDebts + secondProperty;

    if (existingProperty < 0) {
      allPropertyDebts += Math.abs(existingProperty);
    } else {
      allPropertyIncomes += existingProperty;
    }

    const allIncome = personalIncome + allPropertyIncomes;
    const allDebt = personalDebt + allPropertyDebts;
    const totalIncomeNeedToQualify = allDebt * (1 / dti) - allPropertyIncomes;
    const reasoning = (totalIncomeNeedToQualify < allIncome) ? 'noneed' : 'need';
    this.setState(prevState => ({
      ...prevState,
      monthlyPayment,
      result: {
        ...prevState.result,
        dti,
        subjectProperty,
        personalIncome,
        personalDebt,
        allPropertyIncomes,
        allPropertyDebts,
        allIncome,
        allDebt,
        totalIncomeNeedToQualify,
        reasoning,
      }
    }));
  }
  
  render() {
    const { 
      personalIncome,
      personalDebt, 
      allPropertyDebts,
      existingProperty,
      dti,
      allIncome,
      allDebt,
    } = this.state.result;
    return (
      <section className="cosigner-or-not-wrapper">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={12} lg={12}>
              <h5 className="page-title">Cosign Or Not</h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={12} lg={6}>
              <div className="section-title">
                <h6 className="expand-title" onClick={()=> this.handleClickExpandCollapse('personalIncome')}>
                I. Personal Income 
                  { this.state.expandCollapse.personalIncome
                    ? <RemoveIcon className="collapse-icon"/> 
                    : <Fragment>
                      <AddIcon className="expand-icon"/>
                    </Fragment> 
                  }
                </h6>
                { !this.state.expandCollapse.personalIncome ? <span className="badge badge-green total">{currencyFormat(personalIncome)}</span> : null }
              </div>
            </Col>
          </Row>
          {this.state.expandCollapse.personalIncome ? <div className="section-content">
            <Row className="align-items-center">
              <Col xs={12} md={3} lg={2}>
                <label>Base Income</label>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <CurrencyInput value={this.state.baseIncome} name="baseIncome" id="base-income" onChange={this.handleAssumptionsChange} />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={12} md={12} lg={12}>
                <label>Do you have consistent overtime / bonus / commissions for the last 2 years?</label>
                <div className="custom-control custom-radio inline ml-3">
                  <input 
                    type="radio" 
                    className="custom-control-input" 
                    id="consistentOvertimeYes" 
                    name="haveConsistentOvertime" 
                    checked={this.state.haveConsistentOvertime} 
                    onChange={this.handleClickConsistentOvertime} />
                  <label className="custom-control-label" htmlFor="consistentOvertimeYes">Yes</label>
                </div>
                <div className="custom-control custom-radio inline">
                  <input 
                    type="radio" 
                    className="custom-control-input" 
                    id="consistentOvertimeNo" 
                    name="haveConsistentOvertime" 
                    checked={!this.state.haveConsistentOvertime} 
                    onChange={this.handleClickConsistentOvertime} />
                  <label className="custom-control-label" htmlFor="consistentOvertimeNo">No</label>
                </div>
              </Col>
            </Row>
            {this.state.haveConsistentOvertime ? <Row className="align-items-center">
              <Col xs={12} md={3} lg={2}>
                <label>Addtional monthly income</label>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <CurrencyInput value={this.state.addtionalIncome} name="addtionalIncome" id="addtional-income" onChange={this.handleAssumptionsChange} />
              </Col>
            </Row> : null}
            <Row className="align-items-center">
              <Col xs={12} md={12} lg={12}>
                <label>Do you have the second job that you have income for at least 2 years?</label>
                <div className="custom-control custom-radio inline ml-3">
                  <input 
                    type="radio" 
                    className="custom-control-input" 
                    id="otherJobYes" 
                    name="haveOtherJob" 
                    checked={this.state.haveOtherJob} 
                    onChange={this.handleClickOtherJob} />
                  <label className="custom-control-label" htmlFor="otherJobYes">Yes</label>
                </div>
                <div className="custom-control custom-radio inline">
                  <input 
                    type="radio" 
                    className="custom-control-input" 
                    id="otherJobNo" 
                    name="haveOtherJob" 
                    checked={!this.state.haveOtherJob} 
                    onChange={this.handleClickOtherJob} />
                  <label className="custom-control-label" htmlFor="otherJobNo">No</label>
                </div>
              </Col>
            </Row>
            { this.state.haveOtherJob ?(this.state.otherJobs || []).map((item, index) => {
              return (
                <Row key={index}>
                  <Col xs={3} md={3} lg={3}>
                    <MonthlyIncomeNameInput label="Name" value={item.name} name="name" index={index} onChange={this.handleOtherJobChange} />
                  </Col>
                  <Col xs={3} md={3} lg={3}>
                    <MonthlyIncomeInput label="Monthly Income" value={item.income} name="income" index={index} onChange={this.handleOtherJobChange} />
                  </Col>
                  <Col className="incomes-button" xs={3} md={3} lg={3}>
                    { index + 1 === this.state.otherJobs.length 
                      ? <Button variant="primary" className="btn-action btn-income" onClick={this.handleAddOtherJob}>+</Button>  
                      : null }
                    { index + 1 < this.state.otherJobs.length 
                      ? <Button variant="primary" className="btn-delete btn-income" onClick={() => this.handleDeleteOtherJob(index)}>x</Button> 
                      : null }
                  </Col>
                </Row>
              );
            }) : null}
          </div> : null}
          <Row className="align-items-center">
            <Col xs={12} md={12} lg={6}>
              <div className="section-title">
                <h6 className="expand-title" onClick={()=> this.handleClickExpandCollapse('personalDebts')}>
                II. Personal Debts 
                  { this.state.expandCollapse.personalDebts
                    ? <RemoveIcon className="collapse-icon"/> 
                    : <AddIcon className="expand-icon"/>
                  }
                </h6>
                { !this.state.expandCollapse.personalDebts ? <span className="badge badge-green total">{currencyFormat(personalDebt)}</span> : null }
              </div>
            </Col>
          </Row>
          {this.state.expandCollapse.personalDebts ? <div className="section-content">
            <Row className="align-items-center">
              <Col xs={12} md={4} lg={2}>
                <label>Monthly Student loans</label>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <CurrencyInput value={this.state.studentLoans} name="studentLoans" id="student-loans" onChange={this.handleAssumptionsChange} />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={12} md={4} lg={2}>
                <label>Monthly Car loans</label>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <CurrencyInput value={this.state.carLoans} name="carLoans" id="car-loans" onChange={this.handleAssumptionsChange} />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={12} md={4} lg={2}>
                <label>Monthly minimum credit card due</label>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <CurrencyInput value={this.state.creditCardDue} name="creditCardDue" id="credit-card-due" onChange={this.handleAssumptionsChange} />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={12} md={4} lg={2}>
                <label>Other monthly debts</label>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <CurrencyInput value={this.state.otherDebts} name="otherDebts" id="other-debts" onChange={this.handleAssumptionsChange} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <p><b>Please note: monthly utility bills (phone, electronic, gas, water bills) are not a debt.</b></p>
              </Col>
            </Row>
          </div> : null }
          <Row>
            <Col xs={12} md={12} lg={6}>
              <div className="section-title">
                <h6 className="expand-title" onClick={()=> this.handleClickExpandCollapse('subjectProperty')}>
                    III. Subject Property Expenses 
                  {
                    this.state.expandCollapse.subjectProperty
                      ? <RemoveIcon className="collapse-icon"/> 
                      : <AddIcon className="expand-icon"/>
                  }
                </h6>
                { !this.state.expandCollapse.subjectProperty ? <span className="badge badge-green total">{currencyFormat(this.state.result.subjectProperty)}</span> : null }
              </div>
            </Col>
          </Row>
          {this.state.expandCollapse.subjectProperty ? <div className="section-content">
            <Row className="align-items-center">
              <Col xs={12} md={4} lg={2}>
                <label>Loan Amount</label>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <LoanAmountInput value={this.state.loanAmount} onChange={this.handleAssumptionsChange} />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={12} md={4} lg={2}>
                <label>Rate</label>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <RateInput value={this.state.rate} onChange={this.handleAssumptionsChange} />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={12} md={4} lg={2}>
                <label>Monthly Payment</label>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <MonthlyPaymentInput value={this.state.monthlyPayment} disabled={true} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={9} lg={6}>
                <div className="monthly-property-expenses indent-box">
                  <h6>Monthly Property Expenses</h6>
                  <Row className="align-items-center">
                    <Col xs={12} md={4} lg={4}>
                      <label>Monthly Property Tax</label>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <CurrencyInput value={this.state.propertyTax} name="propertyTax" id="property-tax" onChange={this.handleAssumptionsChange} />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col xs={12} md={4} lg={4}>
                      <label>Monthly Insurance</label>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <CurrencyInput value={this.state.monthlyInsurance} name="monthlyInsurance" id="monthly-insurance" onChange={this.handleAssumptionsChange} />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col xs={12} md={4} lg={4}>
                      <label>Monthly HOA</label>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <CurrencyInput value={this.state.monthlyHoa} name="monthlyHoa" id="monthly-hoa" onChange={this.handleAssumptionsChange} />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col xs={12} md={4} lg={4}>
                      <label>Other monthly expenses</label>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <CurrencyInput value={this.state.monthlyExpenses} name="monthlyExpenses" id="monthly-expenses" onChange={this.handleAssumptionsChange} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <label>Is it investment property?</label>
                <div className="custom-control custom-radio inline ml-3">
                  <input 
                    type="radio" 
                    className="custom-control-input" 
                    id="investmentPropertyYes" 
                    name="investmentProperty"
                    checked={this.state.haveInvestmentProperty} 
                    onChange={this.handleClickInvestmentProperty}/>
                  <label className="custom-control-label" htmlFor="investmentPropertyYes">Yes</label>
                </div>
                <div className="custom-control custom-radio inline">
                  <input 
                    type="radio" 
                    className="custom-control-input" 
                    id="investmentPropertyNo" 
                    name="investmentProperty"
                    checked={!this.state.haveInvestmentProperty} 
                    onChange={this.handleClickInvestmentProperty}/>
                  <label className="custom-control-label" htmlFor="investmentPropertyNo">No</label>
                </div>
              </Col>
            </Row>
            {this.state.haveInvestmentProperty ? <Row className="align-items-center">
              <Col xs={12} md={3} lg={2}>
                <label>Ask to enter rent</label>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <CurrencyInput value={this.state.investmentRentValue} name="investmentRentValue" id="investment-rent-value" onChange={this.handleAssumptionsChange} />
              </Col>
            </Row> : null}
          </div> : null }
          <Row className="align-items-center">
            <Col xs={12} md={12} lg={6}>
              <div className="section-title">
                <h6 className="expand-title" onClick={()=> this.handleClickExpandCollapse('secondProperty')}>
                  IV. Second / vacation home expenses {this.state.expandCollapse.secondProperty ? <RemoveIcon className="collapse-icon"/> : <AddIcon className="expand-icon"/>}
                </h6>
                { !this.state.expandCollapse.secondProperty ? <span className="badge badge-green total">{currencyFormat(this.state.result.secondProperty)}</span> : null }
              </div>
            </Col>
          </Row>
          {this.state.expandCollapse.secondProperty ? <div className="section-content">
            {(this.state.secondProperties || []).map((item, index) => {
              return (
                <Row className="align-items-center" key={index}>
                  <Col xs={12} md={9} lg={6}>
                    <div className={'other-property-wrapper' + (index + 1 < this.state.secondProperties.length ? ' delete' : '')}>
                      <div className="other-property-title" >
                        { index + 1 === this.state.secondProperties.length 
                          ? <Button className="btn-property" onClick={this.handleAddSecondProperty}>+</Button>  
                          : null }
                        { index + 1 < this.state.secondProperties.length 
                          ? <Button className="btn-property" onClick={() => this.handleDeleteSecondProperty(index)}>x</Button>  
                          : null }
                      </div>
                      <div className="other-property-form-wrapper">
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Monthly Mortgage</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="mortgage" value={item.mortgage} index={index} id="mortgage" onChange={this.handleSecondPropertyChange} />
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Monthly Tax</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="tax" value={item.tax} index={index} id="tax" onChange={this.handleSecondPropertyChange}/>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Monthly Insurance</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="insurance" value={item.insurance} index={index} id="insurance" onChange={this.handleSecondPropertyChange}/>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Monthly HOA</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="hoa" value={item.hoa} index={index} id="hoa" onChange={this.handleSecondPropertyChange}/>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div> : null}
          <Row className="align-items-center">
            <Col xs={12} md={12} lg={12}>
              <div className="section-title">
                <h6 className="expand-title" onClick={()=> this.handleClickExpandCollapse('existingProperty')}>
                  V. Existing Net Rental Income or Loss for all properties: {this.state.expandCollapse.existingProperty ? <RemoveIcon className="collapse-icon"/> : <AddIcon className="expand-icon"/>}
                </h6>
                { !this.state.expandCollapse.existingProperty ? <span className="badge badge-green total">{currencyFormat(this.state.result.existingProperty)}</span> : null }
              </div>
            </Col>
          </Row>
          {this.state.expandCollapse.existingProperty ? <div className="section-content">
            {(this.state.existingProperties || []).map((item, index) => {
              return (
                <Row className="align-items-center" key={index}>
                  <Col xs={12} md={9} lg={6}>
                    <div className={'other-property-wrapper' + (index + 1 < this.state.existingProperties.length ? ' delete' : '')}>
                      <div className="other-property-title" >
                        { index + 1 === this.state.existingProperties.length 
                          ? <Button className="btn-property" onClick={this.handleAddExistingProperty}>+</Button>  
                          : null }
                        { index + 1 < this.state.existingProperties.length 
                          ? <Button className="btn-property" onClick={() => this.handleDeleteExistingProperty(index)}>x</Button>  
                          : null }
                      </div>
                      <div className="other-property-form-wrapper">
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Ask to enter rent</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="rent" value={item.rent} index={index} id="rent" onChange={this.handleExistingPropertyChange} />
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Monthly Mortgage</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="mortgage" value={item.mortgage} index={index} id="mortgage" onChange={this.handleExistingPropertyChange} />
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Monthly Tax</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="tax" value={item.tax} index={index} id="tax" onChange={this.handleExistingPropertyChange} />
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Monthly Insurance</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="insurance" value={item.insurance} index={index} id="insurance" onChange={this.handleExistingPropertyChange} />
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Monthly HOA</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="hoa" value={item.hoa} index={index} id="hoa" onChange={this.handleExistingPropertyChange} />
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col xs={12} md={3} lg={4}>
                            <label>Monthly expenses filed on Tax return</label>
                          </Col>
                          <Col xs={12} md={5} lg={8}>
                            <CurrencyInput name="filedOnTax" value={item.filedOnTax} index={index} id="filedOnTax" onChange={this.handleExistingPropertyChange} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div> : null}
          { this.state.result.totalIncomeNeedToQualify !== 0 ? <Row>
            <Col xs={12} md={12} lg={8}>
              <div className="indent-box result">
                <ul className="results">
                  <li>
                    Monthly Income needed to qualify: <span className="badge badge-green">{currencyFormat(this.state.result.totalIncomeNeedToQualify)}</span>
                  </li>
                  <li>
                    {
                      this.state.result.reasoning === 'noneed' 
                        ? 'You don\'t need to have a cosigner to be qualified for this loan.' 
                        : <Fragment>
                          You need a co-signer with monthly incomes: <span className="badge badge-red">{currencyFormat(this.state.result.totalIncomeNeedToQualify - this.state.result.personalIncome)}</span>
                        </Fragment>
                    }
                  </li>
                </ul>
              </div>
            </Col>
          </Row> : null }
          { this.state.mode === 'debug' ? <Row className="align-items-center">
            <Col xs={12} md={9} lg={6}>
              <Table bordered className="result-table">
                <tbody>
                  <tr>
                    <td><label>DTI</label></td>
                    <td><span className="badge badge-green">{currencyFormat(dti || 0)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Net rental existing properties</label></td>
                    <td><span className="badge badge-green">{currencyFormat(existingProperty || 0)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Personal Income</label></td>
                    <td><span className="badge badge-green">{currencyFormat(personalIncome || 0)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Personal Debt</label></td>
                    <td><span className="badge badge-green">{currencyFormat(personalDebt || 0)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Property Debt</label></td>
                    <td><span className="badge badge-green">{currencyFormat(allPropertyDebts || 0)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Total Income</label></td>
                    <td><span className="badge badge-green">{currencyFormat(allIncome || 0)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Total Debt</label></td>
                    <td><span className="badge badge-green">{currencyFormat(allDebt || 0)}</span></td>
                  </tr>
                  <tr>
                    <td><label>Total Debt</label></td>
                    <td><span className="badge badge-green">{currencyFormat(allDebt || 0)}</span></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row> : null}
        </Container>
      </section>
    );
  }
}

CosignerOrNot.propTypes = {
  location: PropTypes.object,
};

export default withRouter(CosignerOrNot);