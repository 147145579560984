import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

class MonthlyIncomeNameInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: (this.props.value) || '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const value = (this.props.value) || '';
      this.setState({ value });
    }
  }

  handleChange(event) {
    const value = (event.target.value) || '';
    const fieldName = event.target.name;
    const index = this.props.index || 0;
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value, fieldName, index);
    }
  }
  
  renderMonthlyIncomeNameInput = () => {
    return (
      <Form.Group controlId={`monthly-income-name-${Date.now()}`}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <Form.Control 
          type="text" 
          aria-label={this.props.label}
          name={this.props.name}
          value={this.state.value}
          onChange={this.handleChange}
        />
      </Form.Group>
    );
  }

  render() {
    return this.renderMonthlyIncomeNameInput();
  }
}

MonthlyIncomeNameInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default MonthlyIncomeNameInput;

