import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { currencyFormat } from '../../../utils';
import './style.scss';

class RateComparisonResult extends Component {

  render() {
    let mortgageResult;
    if(this.props.mortgageResult && Object.keys(this.props.mortgageResult).length > 0) {
      const monthlyPayment = this.props.mortgageResult.monthlyPayment;
      const { interestTotal, saving } = this.props.mortgageResult;
      mortgageResult = (
        <Fragment>
          <Row>
            <Col>
              <label>{currencyFormat(monthlyPayment || 0)}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>{currencyFormat(interestTotal || 0)}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>{saving > 0 ? <span className="badge badge-red">{currencyFormat(saving)}</span> : ' '}</label>
            </Col>
          </Row>
        </Fragment>
      );
    }
    return mortgageResult;
  }
}

RateComparisonResult.propTypes = {
  mortgageResult: PropTypes.object,
};

export default RateComparisonResult;