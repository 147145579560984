import React from 'react';
import {
  Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import PortalLayout from './Layouts/Portal';
import AdminLayout from './Layouts/Admin';
import AuthenticationLayout from './Layouts/Authentication';
import HomeContainer from './../../containers/Home';
import MortgageCalculatorContainer from './../../containers/LoanAssistant/MortgageCalculator';
import SmartInterestReductionContainer from './../../containers/LoanAssistant/SmartInterestReduction';
import RateComparisonContainer from './../../containers/LoanAssistant/RateComparison';
import RefinanceOrNotContainer from './../../containers/LoanAssistant/RefinanceOrNot';
import PurchasePowerContainer from './../../containers/LoanAssistant/PurchasePower';
import CosignerOrNotContainer from './../../containers/LoanAssistant/CosignerOrNot';
import SettingsContainer from './../../containers/Admin/Settings/';
import LoginContainer from './../../containers/Login/Login';
import SignUpContainer from './../../containers/SignUp/SignUp';
import { history } from '../../helpers';
import jwt from 'jwt-decode';

import './style.scss';

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => {
    const userStorage = localStorage.getItem('user');
    if (userStorage) {
      const { token } = JSON.parse(userStorage);
      const userInfo = jwt(token); 
      const userRoles = userInfo['cognito:groups'];
      if (userRoles && userRoles.length && userRoles.some(x => x === 'Admin')) {
        return (<Layout><Component {...props} /></Layout>);
      }
    }
    return (<Redirect to='/login' />);
  }} />
);

// eslint-disable-next-line react/prop-types
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout {...rest}>
      <Component {...props} />
    </Layout>
  )} />
);

class App extends React.Component {
  render() {
    return (
      <Router history={ history }>
        <Switch>
          <AppRoute exact path='/' layout={PortalLayout} component={HomeContainer} home={true} />
          <AppRoute exact path='/login' layout={AuthenticationLayout} component={LoginContainer} />
          <AppRoute exact path='/signup' layout={AuthenticationLayout} component={SignUpContainer} />
          <AppRoute exact path='/mortgage-calculator' layout={PortalLayout} component={MortgageCalculatorContainer} />
          <AppRoute exact path='/rate-comparison' layout={PortalLayout} component={RateComparisonContainer} />
          <AppRoute exact path='/smart-interest-reduction' layout={PortalLayout} component={SmartInterestReductionContainer} />
          <AppRoute exact path='/refinance-or-not' layout={PortalLayout} component={RefinanceOrNotContainer} />
          <AppRoute exact path='/purchase-power' layout={PortalLayout} component={PurchasePowerContainer} />
          <AppRoute exact path='/cosigner-or-not' layout={PortalLayout} component={CosignerOrNotContainer} />

          <PrivateRoute exact path='/admin' layout={AdminLayout} component={SettingsContainer} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    );
  }
}

export default App;