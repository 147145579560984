import {ApiService} from './api.service';
let apiService = new ApiService();

export const AuthenticateService = {
  register,
  login,
  verifyAuth,
};

function register(firstName, lastName, phoneNumber, email, password) {
  return apiService.post('public/v1/auth/register', { firstName, lastName, phoneNumber, email, password });
}

function login(email, password) {
  return apiService.post('public/v1/auth/login', { email, password });
}

function verifyAuth() {
  if(localStorage.getItem('user')) {
    return true;
  }
  return false;
}