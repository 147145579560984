import React, { Component, Fragment } from 'react';
import SiteBreadcrumb from '../../components/Breadcrumb';
import MortgageCalculator from '../../components/LoanAssistant/MortgageCalculator/MortgageCalculator';
class MortgageCalculatorContainer extends Component {

  render() {
    const crumbs = [
      { path: '/', name: 'Home'},
      { path: '/', name: 'Mortgage Calculator'},
    ];
    return (
      <Fragment>
        <SiteBreadcrumb crumbs={crumbs}/>
        <MortgageCalculator />
      </Fragment>
    );
  }
}

export default MortgageCalculatorContainer;
