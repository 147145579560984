import {
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from '../actions/types';
  
const INITIAL_STATE = {
  message: '',
};
  
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_FAILURE:
      return {
        ...state,
        message: action.data
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        users: action.data
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        message: action.data
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};