import { RateEngineApiService } from './api.rateEngine.service';
let rateEngineApiService = new RateEngineApiService();

export const LoanTypeService = {
  getLoanTypes,
};

function getLoanTypes() {
  return rateEngineApiService.get('public/v1/loantype');
}
