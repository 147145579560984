import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

class LoanAmountInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: parseFloat(this.props.value)
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(values) {
    const { formattedValue, value } = values;
    this.setState({ value: formattedValue });
    if (this.props.onChange) {
      this.props.onChange(parseFloat(value), 'loanAmount');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && prevProps.value !== this.props.value) {
      const value = parseFloat(this.props.value);
      this.setState({ value });
    }
  }

  renderLoanAmountInput() {
    let result;
    let inputGroup = (
      <InputGroup.Prepend>
        <InputGroup.Text id="loan-amount">$</InputGroup.Text>
      </InputGroup.Prepend>
    );
    if (this.props.float === 'right') {
      inputGroup = (
        <InputGroup.Append>
          <InputGroup.Text id="loan-amount">$</InputGroup.Text>
        </InputGroup.Append>
      );
    }
    result = (
      <Form.Group controlId={`loan-amount-${Date.now()}`}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <InputGroup>
          {this.props.float !== 'right' ? inputGroup : null}
          <NumberFormat
            disabled={this.props.disabled}
            className={this.props.float === 'right' ? 'text-right' : 'text-left'}
            customInput={FormControl} 
            value={this.state.value} 
            thousandSeparator={true} 
            onValueChange={this.handleChange} 
          />
          {this.props.float === 'right' ? inputGroup : null}
        </InputGroup>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderLoanAmountInput();
  }
}

LoanAmountInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  float: PropTypes.string,
  onChange: PropTypes.func,
};

export default LoanAmountInput;

